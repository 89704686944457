import {useSelector} from "react-redux";
import React from "react";
import {RootStore} from "./Data/Store";
import {APP} from "./Utils/App";
import {Home} from "./Home/Home";
import {DiscoveryApp} from "./DiscoveryApp/DiscoveryApp";
import {App} from "./App/App";

export const Main = () => {

    const appState = useSelector((state: RootStore) => state.appReducer)

    const RenderApp = () => {
        switch (appState.app) {
            case APP.HOME:
                return <Home/>
            case APP.APP:
                return <App/>
            case APP.DISCOVERY_APP:
                return <DiscoveryApp/>
            default:
                return <Home/>
        }
    }

    return (RenderApp())
}
