import React from 'react';
import * as ChakraUI from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../Data/Store";
import {theme} from "../../../../Utils/Theme";
import {AdDetailType, UpdateLocalAdAction} from "../../../../Data/Ad/Actions";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {DeselectCreatorAction} from "../../../../Data/Creator/Actions";
import * as Chakra from "@chakra-ui/react";
import {RiEarthFill} from "react-icons/ri";
import {AD_TYPES} from "../../../../Utils/Types";
import {useDisclosure} from "@chakra-ui/react";

export const ResponsiveAdCreator = () => {

    const adState = useSelector((state: RootStore) => state.adReducer)

    return (
        <>
            {adState.is_create_ad_loading
                ?
                <ChakraUI.Center w={"100%"}>
                    <ChakraUI.CircularProgress
                        isIndeterminate
                        size={70}
                        color={theme.light_green}
                        trackColor={theme.black}
                    />
                </ChakraUI.Center>
                :
                <>
                    {adState.ad?.responsive_ad === undefined
                        ? <ResponsiveAdEditor/>
                        : <ResponsiveAdPreview/>
                    }
                </>
            }
        </>
    );
}

const ResponsiveAdEditor = () => {

    const initial_ad: AdDetailType = {
        ad_type: AD_TYPES.RESPONSIVE_AD,
        business_name: "ترويجة",
        business_description: "تطبيق لصنع إعلانات جوجل بالذكاء الاصطناعي",
        business_website: "https://app.tarwiiga.com",
    }

    const dispatch = useDispatch<ThunkDispatch<RootStore, any, AnyAction>>()
    const [ad, setAd] = React.useState<AdDetailType>(initial_ad)
    const [businessNameError, setBusinessNameError] = React.useState("")
    const [businessDescriptionError, setBusinessDescriptionError] = React.useState("")
    const [businessWebsiteError, setBusinessWebsiteError] = React.useState("")

    const onCreate = () => {
        if (ad.business_name === "") {
            setBusinessNameError("Error")
        }
        if (ad.business_description === "") {
            setBusinessDescriptionError("Error")
        }
        if (ad.business_website === "") {
            setBusinessWebsiteError("Error")
        } else {
            const new_ad: AdDetailType = {
                ...ad,
                responsive_ad: {
                    "headlines": [
                        "إعلانات جوجل الذكية",
                        "صنع إعلانات جوجل بذكاء"
                    ],
                    "descriptions": [
                        "تطبيقنا يسهل إنشاء إعلانات جوجل الفعالة بالذكاء الاصطناعي.",
                        "احصل على إعلانات جوجل المثالية مع تطبيقنا الذكي."
                    ]
                }
            }
            dispatch(UpdateLocalAdAction(new_ad))
        }
    }

    const onBack = () => {
        dispatch(DeselectCreatorAction())
    }

    return (
        <ChakraUI.Flex flexDir={"column"} gap={"10"}>

            <Chakra.Text
                textAlign={"center"}
                fontSize={"20px"}
                fontWeight={"bold"}
            >{"إنشاء إعلان زيارة الموقع الإلكتروني"}</Chakra.Text>

            <ChakraUI.Flex flexDir={"column"} gap={"5"}>

                <ChakraUI.Flex flexDir={"column"} gap={"2"} width={"300px"}>
                    <ChakraUI.Text>{`اسم النشاط التجاري`}</ChakraUI.Text>
                    <ChakraUI.Input
                        type={"text"}
                        value={ad.business_name}
                        focusBorderColor={theme.black}
                        color={theme.black}
                        borderColor={businessNameError !== "" ? "red" : theme.black}
                        _hover={{}}
                        onChange={e => {
                            setAd({
                                ...ad!!,
                                business_name: e.target.value
                            })
                            setBusinessNameError("")
                        }}
                    />
                </ChakraUI.Flex>

                <ChakraUI.Flex flexDir={"column"} gap={"2"} width={"300px"}>
                    <ChakraUI.Text>{`وصف النشاط التجاري`}</ChakraUI.Text>
                    <ChakraUI.Textarea
                        value={ad.business_description}
                        focusBorderColor={theme.black}
                        color={theme.black}
                        borderColor={businessDescriptionError !== "" ? "red" : theme.black}
                        _hover={{}}
                        onChange={e => {
                            setAd({
                                ...ad!!,
                                business_description: e.target.value
                            })
                            setBusinessDescriptionError("")
                        }}
                    />
                </ChakraUI.Flex>

                <ChakraUI.Flex flexDir={"column"} gap={"2"} width={"300px"}>
                    <ChakraUI.Text>{`الموقع الإلكتروني للنشاط التجاري`}</ChakraUI.Text>
                    <ChakraUI.Input
                        type={"url"}
                        value={ad.business_website}
                        focusBorderColor={theme.black}
                        color={theme.black}
                        borderColor={businessWebsiteError !== "" ? "red" : theme.black}
                        _hover={{}}
                        onChange={e => {
                            setAd({
                                ...ad!!,
                                business_website: e.target.value
                            })
                            setBusinessWebsiteError("")
                        }}
                    />
                </ChakraUI.Flex>

            </ChakraUI.Flex>

            <Chakra.Flex flexDir={"row"} w={"300px"} gap={"5"}>

                <Chakra.Text
                    w={"50%"}
                    textAlign={"center"}
                    bgColor={theme.light_green}
                    borderColor={theme.black}
                    borderWidth={"1px"}
                    borderRadius={"10px"}
                    p={"10px"}
                    cursor={"pointer"}
                    onClick={onCreate}
                >
                    {"إنشاء"}
                </Chakra.Text>

                <Chakra.Text
                    w={"50%"}
                    textAlign={"center"}
                    borderColor={theme.black}
                    borderWidth={"1px"}
                    borderRadius={"10px"}
                    p={"10px"}
                    cursor={"pointer"}
                    onClick={onBack}
                >
                    {"رجوع"}
                </Chakra.Text>

            </Chakra.Flex>

        </ChakraUI.Flex>
    );
}

const ResponsiveAdPreview = () => {

    const {isOpen, onOpen, onClose} = useDisclosure()

    const dispatch = useDispatch<ThunkDispatch<RootStore, any, AnyAction>>()
    const adState = useSelector((state: RootStore) => state.adReducer)

    const onJoin = () => {
        window.open("https://forms.gle/226GF7JaHmzSKqC36", '_blank')
    }

    const onCancel = () => {
        const new_ad: AdDetailType = {
            ...adState.ad,
            responsive_ad: undefined
        }
        dispatch(UpdateLocalAdAction(new_ad))
        dispatch(DeselectCreatorAction())
    }

    return (
        <ChakraUI.Flex flexDir={"column"} gap={"5"} px={"20px"}>

            <ResponsiveAdCard/>

            <Chakra.Flex flexDir={"row"} w={"100%"} gap={"5"}>

                <Chakra.Text
                    w={"50%"}
                    textAlign={"center"}
                    bgColor={theme.light_green}
                    borderColor={theme.black}
                    borderWidth={"1px"}
                    borderRadius={"10px"}
                    p={"10px"}
                    cursor={"pointer"}
                    onClick={onOpen}
                >
                    {"نشر الإعلان"}
                </Chakra.Text>

                <ChakraUI.Modal isCentered isOpen={isOpen} onClose={onClose}>
                    <ChakraUI.ModalOverlay
                        bg='blackAlpha.300'
                        backdropFilter='blur(15px)'
                    />
                    <ChakraUI.ModalContent mx={"30px"}>
                        <ChakraUI.ModalHeader>{"انضم لقائمة الانتظار"}</ChakraUI.ModalHeader>
                        <ChakraUI.ModalBody>
                            <ChakraUI.Flex flexDir={"column"} gap={"3"} mb={"20px"}>

                                <Chakra.Text textAlign={"center"}>
                                    {"سيتم إطلاق التطبيق قريباً بأمكانك التسجيل للانضمام لقائمة الانتظار وستكون أول المدعون لاستخدام التطبيق وإطلاق حملتك الإعلانية الأولى"}
                                </Chakra.Text>

                                <ChakraUI.Flex mt={"10px"} flexDir={"row"} justifyContent={"space-between"}>

                                    <ChakraUI.Button
                                        bgColor={theme.light_green}
                                        borderWidth={"1px"}
                                        borderRadius={"5px"}
                                        borderColor={theme.black}
                                        _hover={{}}
                                        gap={"2"}
                                        onClick={onJoin}
                                    >
                                        <ChakraUI.Text color={theme.black} fontWeight={"500"}>{"تسجيل"}</ChakraUI.Text>
                                    </ChakraUI.Button>

                                    <ChakraUI.Button
                                        bgColor={theme.white}
                                        borderWidth={"1px"}
                                        borderRadius={"5px"}
                                        borderColor={theme.black}
                                        _hover={{}}
                                        gap={"2"}
                                        onClick={onClose}
                                    >
                                        <ChakraUI.Text color={theme.black} fontWeight={"500"}>{"الغاء"}</ChakraUI.Text>
                                    </ChakraUI.Button>

                                </ChakraUI.Flex>
                            </ChakraUI.Flex>
                        </ChakraUI.ModalBody>
                    </ChakraUI.ModalContent>
                </ChakraUI.Modal>

                <Chakra.Text
                    w={"50%"}
                    textAlign={"center"}
                    borderColor={theme.black}
                    borderWidth={"1px"}
                    borderRadius={"10px"}
                    p={"10px"}
                    cursor={"pointer"}
                    onClick={onCancel}
                >
                    {"إلغاء"}
                </Chakra.Text>

            </Chakra.Flex>

        </ChakraUI.Flex>
    );
}

const ResponsiveAdCard = () => {

    const adState = useSelector((state: RootStore) => state.adReducer)

    const extract_domain = (url: string): string => {
        const regex = /^(?:https?:\/\/)?(?:www\.)?([^/]+)/i;
        const matches = url.match(regex);
        return matches ? matches[1] : "";
    };

    const get_ad_url = (): string => {
        let domain = extract_domain(adState.ad?.business_website!!)
        return `${domain}`
    }

    return (
        <ChakraUI.Flex
            flexDir={"column"}
            gap={"10px"}
            bgColor={"white"}
            p={"20px"}
            borderRadius={"10px"}
            borderWidth={"1px"}
            borderColor={"#dcdcdc"}
        >
            <ChakraUI.Text fontWeight={"bold"}>{"إعلان"}</ChakraUI.Text>
            <ChakraUI.Flex
                flexDir={"row"}
                gap={"10px"}
                justifyContent={"start"}
                alignItems={"center"}
            >
                <ChakraUI.Center
                    w={"35px"}
                    height={"35px"}
                    borderRadius={"35px"}
                    bgColor={"#e2eeff"}
                >
                    <RiEarthFill size={"22px"} color={"blue"}/>
                </ChakraUI.Center>
                <ChakraUI.Flex flexDir={"column"}>
                    <ChakraUI.Text fontSize={"16px"}>{adState.ad?.business_name}</ChakraUI.Text>
                    <ChakraUI.Text fontSize={"14px"} dir={"ltr"}>{get_ad_url()}</ChakraUI.Text>
                </ChakraUI.Flex>
            </ChakraUI.Flex>

            <ChakraUI.Flex flexDir={"row"}>
                <ChakraUI.Text color={"#1458d6"} fontSize={"20px"}>
                    {adState.ad?.responsive_ad!!.headlines.map((headline: string, index: number) => {
                        return (
                            <span key={index}>
                                    {headline}
                                {adState.ad?.responsive_ad!!.headlines.length!! - 1 === index ? "" : " | "}
                                </span>
                        )
                    })}
                </ChakraUI.Text>
            </ChakraUI.Flex>

            <ChakraUI.Flex flexDir={"row"}>
                <ChakraUI.Text maxWidth={"400px"}>
                    {adState.ad?.responsive_ad!!.descriptions.map((description: string, index: number) => {
                        return (
                            <span key={index}>
                                    {description}
                                {adState.ad?.responsive_ad!!.descriptions.length!! - 1 === index ? "" : " "}
                                </span>
                        )
                    })}
                </ChakraUI.Text>
            </ChakraUI.Flex>

        </ChakraUI.Flex>
    )
}

