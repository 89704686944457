import {PAGE_SELECT, PageDispatchTypes, PageType} from "./Actions";
import {getPage} from "../../Utils/Page";

interface PageState {
    page: PageType,
}

const defaultState: PageState = {
    page: getPage(localStorage.getItem("page")!!),
}

export const PageReducer = (state: PageState = defaultState, action: PageDispatchTypes): PageState => {
    switch (action.type) {
        case PAGE_SELECT:
            return {
                ...state,
                page: action.page
            }
        default:
            return state
    }
}
