import * as Chakra from "@chakra-ui/react";
import React from "react";
import {Header} from "./Components/Header";
import {Footer} from "./Components/Footer";
import {steps, home_info} from "../Utils/Info";
import {theme} from "../Utils/Theme";
import app_demo_img from '../Assets/app_demo_img.png'
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {RootStore} from "../Data/Store";
import {AnyAction} from "redux";
import {APP, getApp} from "../Utils/App";
import {SelectAppAction} from "../Data/App/Actions";
import * as ChakraUI from "@chakra-ui/react";
import {useDisclosure} from "@chakra-ui/react";

export const Home = () => {

    const {isOpen, onOpen, onClose} = useDisclosure()

    const dispatch = useDispatch<ThunkDispatch<RootStore, any, AnyAction>>()

    const onSelectApp = (id: string) => {
        const app = getApp(id)
        dispatch(SelectAppAction(app))
    }

    return (
        <Chakra.Flex flexDir={"column"} w={"100%"} minH={"100vh"}>
            <Header/>
            <Chakra.Flex flexDir={"column"} px={"50px"} pb={"100px"} pt={"50px"} gap={"5"}>

                <Chakra.Center flexDir={"column"} gap={"10px"} textAlign={"center"}>
                    <Chakra.Text fontSize={"40px"} fontWeight={"bold"}>
                        {home_info.headline1}
                    </Chakra.Text>
                    <Chakra.Text fontSize={"25px"} fontWeight={"bold"}>
                        {home_info.headline2}
                    </Chakra.Text>
                    <Chakra.Text fontSize={"20px"}>
                        {home_info.description}
                    </Chakra.Text>
                    <Chakra.Button
                        mt={"10px"}
                        colorScheme={"none"}
                        bgColor={theme.light_green}
                        color={theme.black}
                        borderRadius={"10px"}
                        borderWidth={"1px"}
                        borderColor={"black"}
                        fontSize={"18px"}
                        fontWeight={"bold"}
                        onClick={() => {
                            window.open("https://forms.gle/Hv1CMf682YAhhWFaA", '_blank')
                        }}
                    >{"انضم لقائمة الانتظار"}</Chakra.Button>
                    <Chakra.Image src={app_demo_img} w={"300px"}/>
                </Chakra.Center>

                {steps.map((step, index) => (
                    <Chakra.Center
                        key={index}
                        flexDir={"column"}
                        gap={"3"}
                        textAlign={"center"}
                        borderWidth={"1px"}
                        borderRadius={"10px"}
                        borderColor={theme.black}
                        p={"20px"}
                        bgColor={theme.light_green}
                        w={["100%", "80%", "50%", "30%"]}
                        mx={"auto"}
                    >

                        <Chakra.Text fontSize={"25px"} fontWeight={"bold"}>{step.title}</Chakra.Text>
                        <Chakra.Text fontSize={"18px"} fontWeight={"bold"}>{step.description}</Chakra.Text>
                        <Chakra.Button
                            colorScheme={"none"}
                            bgColor={theme.black}
                            color={theme.light_green}
                            borderRadius={"10px"}
                            borderWidth={"1px"}
                            borderColor={"black"}
                            fontSize={"18px"}
                            fontWeight={"bold"}
                            onClick={() => {
                                if (step.id === "watch") {
                                    onOpen()
                                } else if (step.id === "discover") {
                                    onSelectApp(APP.DISCOVERY_APP)
                                } else if (step.id === "try") {
                                    onSelectApp(APP.APP)
                                } else {
                                    window.open(step.call_to_action_url, '_blank')
                                }
                            }}
                        >{step.call_to_action_title}</Chakra.Button>

                    </Chakra.Center>
                ))}

                <ChakraUI.Modal isCentered isOpen={isOpen} onClose={onClose}>
                    <ChakraUI.ModalOverlay
                        bg='blackAlpha.300'
                        backdropFilter='blur(15px)'
                    />
                    <ChakraUI.ModalContent m={"50px"} borderRadius={"10px"}>
                        <ChakraUI.ModalBody p={"0px"}>
                            <ChakraUI.Center>
                                <video
                                    width="100%"
                                    height="1920px"
                                    autoPlay
                                    controls
                                    muted={false}
                                    style={{borderRadius: "10px"}}
                                >
                                    <source src={home_info.demo_video} type="video/mp4"/>
                                    Your browser does not support the video tag.
                                </video>
                            </ChakraUI.Center>
                        </ChakraUI.ModalBody>
                    </ChakraUI.ModalContent>
                </ChakraUI.Modal>

            </Chakra.Flex>
            <Footer/>
        </Chakra.Flex>
    );
}
