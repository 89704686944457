import {applyMiddleware, combineReducers, legacy_createStore as createStore} from "redux";
import thunk from "redux-thunk";
import {AppReducer} from "./App/Reducer";
import {PageReducer} from "./Page/Reducer";
import {CreatorReducer} from "./Creator/Reducer";
import {AdReducer} from "./Ad/Reducer";
import {InvitationReducer} from "./Invitation/Reducer";

const rootReducer = combineReducers({
    appReducer: AppReducer,
    invitationReducer: InvitationReducer,
    pageReducer: PageReducer,
    creatorReducer: CreatorReducer,
    adReducer: AdReducer
})

export const Store = createStore(rootReducer, applyMiddleware(thunk))

export type RootStore = ReturnType<typeof rootReducer>
