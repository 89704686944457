import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../Data/Store";
import {PAGE, pages} from "../Utils/Page";
import * as Chakra from "@chakra-ui/react";
import {IoMenu} from "react-icons/io5";
import {theme} from "../Utils/Theme";
import {PageType, SelectPageAction} from "../Data/Page/Actions";
import {AdPage} from "./Pages/AdPage/AdPage";
import {SupportPage} from "./Pages/SupportPage/SupportPage";
import {WalletPage} from "./Pages/WalletPage/WalletPage";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import logo from '../Assets/logo.svg';
import {ReportPage} from "./Pages/ReportPage/ReporPaget";
import {APP, getApp} from "../Utils/App";
import {SelectAppAction} from "../Data/App/Actions";

export const DiscoveryApp = () => {

    const pageState = useSelector((state: RootStore) => state.pageReducer)

    const RenderPage = () => {
        switch (pageState.page?.id) {
            case PAGE.AD_PAGE:
                return <AdPage/>
            case PAGE.REPORT_PAGE:
                return <ReportPage/>
            case PAGE.WALLET_PAGE:
                return <WalletPage/>
            case PAGE.SUPPORT_PAGE:
                return <SupportPage/>
            default:
                return <AdPage/>
        }
    }

    return (
        <Chakra.Flex
            flexDir={"column"}
            minW={"100%"}
            minH={"100vh"}
            justifyContent={"space-between"}
            alignItems={"center"}
        >
            <TopNavigation/>
            <Chakra.Center
                flexDir={"column"}
                pt={"100px"}
                pb={"200px"}
                w={"100%"}
                minH={"100vh"}
                overflowY={"scroll"}
            >
                {RenderPage()}
            </Chakra.Center>
            <BottomNavigation/>
        </Chakra.Flex>
    )
}

const TopNavigation = () => {

    const dispatch = useDispatch<ThunkDispatch<RootStore, any, AnyAction>>()

    const onSelectApp = (id: string) => {
        const app = getApp(id)
        dispatch(SelectAppAction(app))
    }

    return (
        <Chakra.Flex
            flexDir={"row"}
            w={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
            position={"fixed"}
            bgColor={"white"}
            top={0}
            zIndex={10}
            px={"30px"}
            pt={"30px"}
            pb={"20px"}
        >
            <Chakra.Flex
                flexDir={"row"}
                gap={"2"}
                alignItems={"center"}
                cursor={"pointer"}
                onClick={() => onSelectApp(APP.HOME)}
            >

                <Chakra.Center
                    w={"40px"}
                    h={"40px"}
                    bgColor={theme.light_green}
                    borderWidth={"1px"}
                    borderColor={theme.black}
                    borderRadius={"10px"}
                >
                    <Chakra.Image src={logo} w={"50px"} h={"50px"}/>
                </Chakra.Center>

            </Chakra.Flex>

            <Chakra.Icon as={IoMenu} w={"40px"} h={"40px"} color={theme.black}/>
        </Chakra.Flex>
    );
}

const BottomNavigation = () => {

    const dispatch = useDispatch<ThunkDispatch<RootStore, any, AnyAction>>()
    const pageState = useSelector((state: RootStore) => state.pageReducer)

    const onSelectPage = (page: PageType) => {
        dispatch(SelectPageAction(page))
    }

    return (
        <Chakra.Flex
            flexDir={"row"}
            w={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
            position={"fixed"}
            bgColor={"white"}
            bottom={0}
            zIndex={10}
            p={"30px"}
        >

            {pages.map((page: PageType) => (
                <Chakra.Center
                    key={page.id}
                    bgColor={pageState.page?.id === page.id ? theme.black : ""}
                    borderRadius={"50%"}
                    p={"10px"}
                >
                    <Chakra.Icon
                        as={page.icon}
                        w={pageState.page?.id === page.id ? "30px" : "40px"}
                        h={pageState.page?.id === page.id ? "30px" : "40px"}
                        color={pageState.page?.id === page.id ? theme.light_green : theme.black}
                        cursor={"pointer"}
                        onClick={() => onSelectPage(page)}
                    />
                </Chakra.Center>
            ))}

        </Chakra.Flex>
    );
}
