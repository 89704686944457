export type CountryType = {
    name: string,
    code: string,
    cities: string[]
}

export const COUNTRIES = {
    EGYPT: "EG",
    SAUDI_ARABIA: "SA",
    UNITED_ARAB_EMIRATES: "AE",
}

export const countries: CountryType[] = [
    // {
    //     name: "مصر",
    //     code: COUNTRIES.EGYPT,
    //     cities: [
    //         "القاهرة",
    //         "الجيزة",
    //         "الأسكندرية"
    //     ]
    // },
    {
        name: "السعودية",
        code: COUNTRIES.SAUDI_ARABIA,
        cities: [
            "الرياض",
            "مكة المكرمة",
            "الدمام"
        ]
    },
    // {
    //     name: "الإمارات",
    //     code: COUNTRIES.UNITED_ARAB_EMIRATES,
    //     cities: [
    //         "دبي",
    //         "أبوظبي",
    //         "الشارقة"
    //     ]
    // },
]

export const getCountry = (code: string) => {
    const index = countries.findIndex(country => country.code === code)
    return countries[index]
}

export const AD_TYPES = {
    CALL_AD: "Call Ad",
    RESPONSIVE_AD: "Responsive Ad",
    SHOPPING_AD: "Shopping Ad",
    APP_AD: "Main Ad",
}