import {
    AD_CREATE_ERROR,
    AD_CREATE_REQUEST,
    AD_CREATE_SUCCESS,
    AdDetailType,
    AdDispatchTypes,
    UPDATE_LOCAL_AD,
} from "./Actions";

interface AdState {
    ad: AdDetailType | null,
    status: number,
    is_create_ad_loading: boolean,
    create_ad_success_message: string,
    create_ad_error_message: string,
}

const defaultState: AdState = {
    ad: null,
    status: 0,
    is_create_ad_loading: false,
    create_ad_success_message: "",
    create_ad_error_message: "",
}

export const AdReducer = (
    state: AdState = defaultState, action: AdDispatchTypes
): AdState => {
    switch (action.type) {
        case AD_CREATE_REQUEST:
            return {
                ...state,
                is_create_ad_loading: true,
            }
        case AD_CREATE_SUCCESS:
            return {
                ...state,
                is_create_ad_loading: false,
                status: action.ad_response.status,
                create_ad_success_message: action.ad_response.message,
                create_ad_error_message: "",
                ad: action.ad_response.ad,
            }
        case AD_CREATE_ERROR:
            return {
                ...state,
                is_create_ad_loading: false,
                status: action.ad_response.status,
                create_ad_success_message: "",
                create_ad_error_message: action.ad_response.message,
            }
        case UPDATE_LOCAL_AD:
            return {
                ...state,
                ad: action.ad,
            }
        default:
            return state
    }
}

