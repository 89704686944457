import React from 'react';
import * as ChakraUI from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../Data/Store";
import {theme} from "../../../../Utils/Theme";
import {AdDetailType, UpdateLocalAdAction} from "../../../../Data/Ad/Actions";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {DeselectCreatorAction} from "../../../../Data/Creator/Actions";
import * as Chakra from "@chakra-ui/react";
import {BsFillInfoCircleFill} from "react-icons/bs";
import logo from '../../../../Assets/logo.svg';
import {IoMdDownload, IoMdStar, IoMdStarHalf} from "react-icons/io";
import {AD_TYPES} from "../../../../Utils/Types";
import {useDisclosure} from "@chakra-ui/react";

export const AppAdCreator = () => {

    const adState = useSelector((state: RootStore) => state.adReducer)

    return (
        <>
            {adState.is_create_ad_loading
                ?
                <ChakraUI.Center w={"100%"}>
                    <ChakraUI.CircularProgress
                        isIndeterminate
                        size={70}
                        color={theme.light_green}
                        trackColor={theme.black}
                    />
                </ChakraUI.Center>
                :
                <>
                    {adState.ad?.app_ad === undefined
                        ? <AppAdEditor/>
                        : <AppAdPreview/>
                    }
                </>
            }
        </>
    );
}

const AppAdEditor = () => {

    const initial_ad: AdDetailType = {
        ad_type: AD_TYPES.APP_AD,
        app_url: "https://play.google.com/store/apps/details?id=com.tarwiiga",
        app_name: "ترويجة",
        app_description: "تطبيق لصنع إعلانات جوجل بالذكاء الاصطناعي",
    }

    const dispatch = useDispatch<ThunkDispatch<RootStore, any, AnyAction>>()

    const [ad, setAd] = React.useState<AdDetailType>(initial_ad)
    const [appUrlError, setAppUrlError] = React.useState("")
    const [appNameError, setAppNameError] = React.useState("")
    const [appDescriptionError, setAppDescriptionError] = React.useState("")

    const onCreate = () => {
        if (ad.app_url === "") {
            setAppUrlError("Error")
        }
        if (ad.app_name === "") {
            setAppNameError("Error")
        }
        if (ad.app_description === "") {
            setAppDescriptionError("Error")
        }
        else {
            const new_ad: AdDetailType = {
                ...ad,
                app_ad: {
                    "headlines": [
                        "إعلانات جوجل الذكية",
                        "صنع إعلانات AI"
                    ],
                    "descriptions": [
                        "صمم إعلانات جوجل فعالة بالذكاء الاصطناعي. حمل التطبيق الآن!",
                        "أدوات قوية لإنشاء إعلانات جوجل تجذب العملاء. جربها الآن!"
                    ]
                }
            }
            dispatch(UpdateLocalAdAction(new_ad))
        }
    }

    const onBack = () => {
        dispatch(DeselectCreatorAction())
    }

    return (
        <ChakraUI.Flex flexDir={"column"} gap={"10"}>

            <Chakra.Text
                textAlign={"center"}
                fontSize={"20px"}
                fontWeight={"bold"}
            >{"إنشاء إعلان تنزيل التطبيق"}</Chakra.Text>

            <ChakraUI.Flex flexDir={"column"} gap={"5"}>

                <ChakraUI.Flex flexDir={"column"} gap={"2"} width={"300px"}>
                    <ChakraUI.Text>{`رابط التطبيق`}</ChakraUI.Text>
                    <ChakraUI.Input
                        type={"url"}
                        value={ad.app_url}
                        focusBorderColor={theme.black}
                        color={theme.black}
                        borderColor={appUrlError !== "" ? "red" : theme.black}
                        _hover={{}}
                        onChange={e => {
                            setAd({
                                ...ad!!,
                                app_url: e.target.value
                            })
                            setAppUrlError("")
                        }}
                    />
                </ChakraUI.Flex>

                <ChakraUI.Flex flexDir={"column"} gap={"2"} width={"300px"}>
                    <ChakraUI.Text>{`اسم التطبيق`}</ChakraUI.Text>
                    <ChakraUI.Input
                        type={"text"}
                        value={ad.app_name}
                        focusBorderColor={theme.black}
                        color={theme.black}
                        borderColor={appNameError !== "" ? "red" : theme.black}
                        _hover={{}}
                        onChange={e => {
                            setAd({
                                ...ad!!,
                                app_name: e.target.value
                            })
                            setAppNameError("")
                        }}
                    />
                </ChakraUI.Flex>

                <ChakraUI.Flex flexDir={"column"} gap={"2"} width={"300px"}>
                    <ChakraUI.Text>{`وصف التطبيق`}</ChakraUI.Text>
                    <ChakraUI.Textarea
                        value={ad.app_description}
                        focusBorderColor={theme.black}
                        color={theme.black}
                        borderColor={appDescriptionError !== "" ? "red" : theme.black}
                        _hover={{}}
                        onChange={e => {
                            setAd({
                                ...ad!!,
                                app_description: e.target.value
                            })
                            setAppDescriptionError("")
                        }}
                    />
                </ChakraUI.Flex>

            </ChakraUI.Flex>

            <Chakra.Flex flexDir={"row"} w={"300px"} gap={"5"}>

                <Chakra.Text
                    w={"50%"}
                    textAlign={"center"}
                    bgColor={theme.light_green}
                    borderColor={theme.black}
                    borderWidth={"1px"}
                    borderRadius={"10px"}
                    p={"10px"}
                    cursor={"pointer"}
                    onClick={onCreate}
                >
                    {"إنشاء"}
                </Chakra.Text>

                <Chakra.Text
                    w={"50%"}
                    textAlign={"center"}
                    borderColor={theme.black}
                    borderWidth={"1px"}
                    borderRadius={"10px"}
                    p={"10px"}
                    cursor={"pointer"}
                    onClick={onBack}
                >
                    {"رجوع"}
                </Chakra.Text>

            </Chakra.Flex>

        </ChakraUI.Flex>
    );
}

const AppAdPreview = () => {

    const {isOpen, onOpen, onClose} = useDisclosure()

    const dispatch = useDispatch<ThunkDispatch<RootStore, any, AnyAction>>()
    const adState = useSelector((state: RootStore) => state.adReducer)

    const onJoin = () => {
        window.open("https://forms.gle/226GF7JaHmzSKqC36", '_blank')
    }

    const onCancel = () => {
        const new_ad: AdDetailType = {
            ...adState.ad,
            app_ad: undefined
        }
        dispatch(UpdateLocalAdAction(new_ad))
        dispatch(DeselectCreatorAction())
    }

    return (
        <ChakraUI.Flex flexDir={"column"} gap={"10"} px={"20px"}>

            <AppAdCard/>

            <Chakra.Flex flexDir={"row"} w={"100%"} gap={"5"}>

                <Chakra.Text
                    w={"50%"}
                    textAlign={"center"}
                    bgColor={theme.light_green}
                    borderColor={theme.black}
                    borderWidth={"1px"}
                    borderRadius={"10px"}
                    p={"10px"}
                    cursor={"pointer"}
                    onClick={onOpen}
                >
                    {"نشر الإعلان"}
                </Chakra.Text>

                <ChakraUI.Modal isCentered isOpen={isOpen} onClose={onClose}>
                    <ChakraUI.ModalOverlay
                        bg='blackAlpha.300'
                        backdropFilter='blur(15px)'
                    />
                    <ChakraUI.ModalContent mx={"30px"}>
                        <ChakraUI.ModalHeader>{"انضم لقائمة الانتظار"}</ChakraUI.ModalHeader>
                        <ChakraUI.ModalBody>
                            <ChakraUI.Flex flexDir={"column"} gap={"3"} mb={"20px"}>

                                <Chakra.Text textAlign={"center"}>
                                    {"سيتم إطلاق التطبيق قريباً بأمكانك التسجيل للانضمام لقائمة الانتظار وستكون أول المدعون لاستخدام التطبيق وإطلاق حملتك الإعلانية الأولى"}
                                </Chakra.Text>

                                <ChakraUI.Flex mt={"10px"} flexDir={"row"} justifyContent={"space-between"}>

                                    <ChakraUI.Button
                                        bgColor={theme.light_green}
                                        borderWidth={"1px"}
                                        borderRadius={"5px"}
                                        borderColor={theme.black}
                                        _hover={{}}
                                        gap={"2"}
                                        onClick={onJoin}
                                    >
                                        <ChakraUI.Text color={theme.black} fontWeight={"500"}>{"تسجيل"}</ChakraUI.Text>
                                    </ChakraUI.Button>

                                    <ChakraUI.Button
                                        bgColor={theme.white}
                                        borderWidth={"1px"}
                                        borderRadius={"5px"}
                                        borderColor={theme.black}
                                        _hover={{}}
                                        gap={"2"}
                                        onClick={onClose}
                                    >
                                        <ChakraUI.Text color={theme.black} fontWeight={"500"}>{"الغاء"}</ChakraUI.Text>
                                    </ChakraUI.Button>

                                </ChakraUI.Flex>
                            </ChakraUI.Flex>
                        </ChakraUI.ModalBody>
                    </ChakraUI.ModalContent>
                </ChakraUI.Modal>

                <Chakra.Text
                    w={"50%"}
                    textAlign={"center"}
                    borderColor={theme.black}
                    borderWidth={"1px"}
                    borderRadius={"10px"}
                    p={"10px"}
                    cursor={"pointer"}
                    onClick={onCancel}
                >
                    {"إلغاء"}
                </Chakra.Text>

            </Chakra.Flex>

        </ChakraUI.Flex>
    );
}

const AppAdCard = () => {

    const adState = useSelector((state: RootStore) => state.adReducer)

    return (
        <ChakraUI.Flex
            flexDir={"column"}
            gap={"2"}
            bgColor={"white"}
            p={"20px"}
            borderRadius={"10px"}
            borderWidth={"1px"}
            borderColor={"#dcdcdc"}
        >
            <ChakraUI.Flex flexDir={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <ChakraUI.Text>
                    <span style={{fontWeight: "bold"}}>إعلان</span> . <span>Google Play</span>
                </ChakraUI.Text>
                <BsFillInfoCircleFill fontSize={"10px"} color={theme.gray}/>
            </ChakraUI.Flex>

            <ChakraUI.Flex flexDir={"row"} gap={"3"}>
                <ChakraUI.Image src={logo} w={"50px"} h={"50px"}/>
                <ChakraUI.Flex flexDir={"column"}>
                    <ChakraUI.Text color={"#1458d6"} fontSize={"20px"}>
                        {"ترويجة"}
                        {adState.ad?.app_ad!!.headlines.map((headline: string, index: number) => {
                            return <span key={index}>{` | ${headline}`}</span>
                        })}
                    </ChakraUI.Text>
                    <ChakraUI.Flex flexDir={"row"} alignItems={"center"} gap={"2"}>
                        <ChakraUI.Text>{"4.4"}</ChakraUI.Text>
                        <ChakraUI.Flex flexDir={"row"}>
                            <IoMdStar fontSize={"15px"} color={"#ee8720"}/>
                            <IoMdStar fontSize={"15px"} color={"#ee8720"}/>
                            <IoMdStar fontSize={"15px"} color={"#ee8720"}/>
                            <IoMdStar fontSize={"15px"} color={"#ee8720"}/>
                            <IoMdStarHalf fontSize={"15px"} color={"#ee8720"}/>
                        </ChakraUI.Flex>
                        <ChakraUI.Text>{"(399)"}</ChakraUI.Text>
                    </ChakraUI.Flex>
                </ChakraUI.Flex>
            </ChakraUI.Flex>

            <ChakraUI.Text>
                {adState.ad?.app_ad!!.descriptions.map((description: string, index: number) => {
                    return <span key={index}>{` ${description}`}</span>
                })}
            </ChakraUI.Text>

            <ChakraUI.Flex flexDir={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <ChakraUI.Text color={"#737373"}>{"مجاني"}</ChakraUI.Text>
                <ChakraUI.Flex
                    flexDir={"row"}
                    borderRadius={"20px"}
                    borderWidth={"1px"}
                    borderColor={"#dcdcdc"}
                    gap={"2"}
                    py={"10px"}
                    px={"15px"}
                >
                    <IoMdDownload fontSize={"20px"} color={"#1458d6"}/>
                    <ChakraUI.Text>{"تنزيل"}</ChakraUI.Text>
                </ChakraUI.Flex>
            </ChakraUI.Flex>

        </ChakraUI.Flex>
    )
}

