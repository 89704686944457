import React from 'react';
import * as ChakraUI from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../Data/Store";
import {theme} from "../../../../Utils/Theme";
import {AdDetailType, UpdateLocalAdAction} from "../../../../Data/Ad/Actions";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {DeselectCreatorAction} from "../../../../Data/Creator/Actions";
import * as Chakra from "@chakra-ui/react";
import {AD_TYPES} from "../../../../Utils/Types";
import product_img from "../../../../Assets/product_img.jpg";
import {useDisclosure} from "@chakra-ui/react";

export const ShoppingAdCreator = () => {

    const adState = useSelector((state: RootStore) => state.adReducer)

    return (
        <>
            {adState.is_create_ad_loading
                ?
                <ChakraUI.Center w={"100%"}>
                    <ChakraUI.CircularProgress
                        isIndeterminate
                        size={70}
                        color={theme.light_green}
                        trackColor={theme.black}
                    />
                </ChakraUI.Center>
                :
                <>
                    {adState.ad?.shopping_ad === undefined
                        ? <ShoppingAdEditor/>
                        : <ShoppingAdPreview/>
                    }
                </>
            }
        </>
    );
}

const ShoppingAdEditor = () => {

    const initial_ad: AdDetailType = {
        ad_type: AD_TYPES.SHOPPING_AD,
        product_image: "",
        product_url: "https://shop.tarwiiga.com/google-ad",
        product_title: "إعلان جوجل | بالذكاء الاصطناعي | أول نتيجة",
        product_description: "إعلانك على جوجل بالذكاء الاصطناعي، إعلانات مصممة بدقة لاستهداف جمهورك وزيادة العائد على الاستثمار",
        product_price: "٩٩ ريال",
        product_brand: "ترويجة",
    }

    const dispatch = useDispatch<ThunkDispatch<RootStore, any, AnyAction>>()
    const adState = useSelector((state: RootStore) => state.adReducer)

    const [ad, setAd] = React.useState<AdDetailType>(initial_ad)
    const [productUrlError, setProductUrlError] = React.useState("")
    const [productTitleError, setProductTitleError] = React.useState("")
    const [productDescriptionError, setProductDescriptionError] = React.useState("")
    const [productPriceError, setProductPriceError] = React.useState("")
    const [productBrandError, setProductBrandError] = React.useState("")

    const onCreate = () => {
        if (ad.product_url === "") {
            setProductUrlError("Error")
        }
        if (ad.product_title === "") {
            setProductTitleError("Error")
        }
        if (ad.product_description === "") {
            setProductDescriptionError("Error")
        }
        if (ad.product_price === "") {
            setProductPriceError("Error")
        }
        if (ad.product_brand === "") {
            setProductBrandError("Error")
        }
        else {
            const new_ad: AdDetailType = {
                ...adState.ad,
                shopping_ad: {
                    title: ad.product_title!!,
                    price: ad.product_price!!,
                    brand: ad.product_brand!!,
                }
            }
            dispatch(UpdateLocalAdAction(new_ad))
        }
    }

    const onBack = () => {
        dispatch(DeselectCreatorAction())
    }

    return (
        <ChakraUI.Flex flexDir={"column"} gap={"10"}>

            <ChakraUI.Flex flexDir={"column"} gap={"5"}>

                <ChakraUI.Flex flexDir={"column"} gap={"2"} width={"300px"}>
                    <ChakraUI.Text>{`صورة المنتج`}</ChakraUI.Text>
                    <Chakra.Center
                        flexDir={"column"}
                        w={"100%"}
                        h={"200px"}
                        borderWidth={"1px"}
                        borderRadius={"10px"}
                        borderColor={theme.black}
                    >
                        <Chakra.Image src={product_img} borderRadius={"10px"}/>
                    </Chakra.Center>
                </ChakraUI.Flex>

                <ChakraUI.Flex flexDir={"column"} gap={"2"} width={"300px"}>
                    <ChakraUI.Text>{`رابط المنتج`}</ChakraUI.Text>
                    <ChakraUI.Input
                        type={"url"}
                        value={ad.product_url}
                        focusBorderColor={theme.black}
                        color={theme.black}
                        borderColor={productUrlError !== "" ? "red" : theme.black}
                        _hover={{}}
                        onChange={e => {
                            setAd({
                                ...ad!!,
                                product_url: e.target.value
                            })
                            setProductUrlError("")
                        }}
                    />
                </ChakraUI.Flex>

                <ChakraUI.Flex flexDir={"column"} gap={"2"} width={"300px"}>
                    <ChakraUI.Text>{`عنوان المنتج`}</ChakraUI.Text>
                    <ChakraUI.Input
                        type={"text"}
                        value={ad.product_title}
                        focusBorderColor={theme.black}
                        color={theme.black}
                        borderColor={productTitleError !== "" ? "red" : theme.black}
                        _hover={{}}
                        onChange={e => {
                            setAd({
                                ...ad!!,
                                product_title: e.target.value
                            })
                            setProductTitleError("")
                        }}
                    />
                </ChakraUI.Flex>

                <ChakraUI.Flex flexDir={"column"} gap={"2"} width={"300px"}>
                    <ChakraUI.Text>{`وصف المنتج`}</ChakraUI.Text>
                    <ChakraUI.Textarea
                        value={ad.product_description}
                        focusBorderColor={theme.black}
                        color={theme.black}
                        borderColor={productDescriptionError !== "" ? "red" : theme.black}
                        _hover={{}}
                        onChange={e => {
                            setAd({
                                ...ad!!,
                                product_description: e.target.value
                            })
                            setProductDescriptionError("")
                        }}
                    />
                </ChakraUI.Flex>

                <ChakraUI.Flex flexDir={"column"} gap={"2"} width={"300px"}>
                    <ChakraUI.Text>{`سعر المنتج`}</ChakraUI.Text>
                    <ChakraUI.Input
                        type={"text"}
                        value={ad.product_price}
                        focusBorderColor={theme.black}
                        color={theme.black}
                        borderColor={productPriceError !== "" ? "red" : theme.black}
                        _hover={{}}
                        onChange={e => {
                            setAd({
                                ...ad!!,
                                product_price: e.target.value
                            })
                            setProductPriceError("")
                        }}
                    />
                </ChakraUI.Flex>

                <ChakraUI.Flex flexDir={"column"} gap={"2"} width={"300px"}>
                    <ChakraUI.Text>{`اسم البراند`}</ChakraUI.Text>
                    <ChakraUI.Input
                        type={"text"}
                        value={ad.product_brand}
                        focusBorderColor={theme.black}
                        color={theme.black}
                        borderColor={productBrandError !== "" ? "red" : theme.black}
                        _hover={{}}
                        onChange={e => {
                            setAd({
                                ...ad!!,
                                product_brand: e.target.value
                            })
                            setProductBrandError("")
                        }}
                    />
                </ChakraUI.Flex>

            </ChakraUI.Flex>

            <Chakra.Flex flexDir={"row"} w={"300px"} gap={"5"}>

                <Chakra.Text
                    w={"50%"}
                    textAlign={"center"}
                    bgColor={theme.light_green}
                    borderColor={theme.black}
                    borderWidth={"1px"}
                    borderRadius={"10px"}
                    p={"10px"}
                    cursor={"pointer"}
                    onClick={onCreate}
                >
                    {"إنشاء"}
                </Chakra.Text>

                <Chakra.Text
                    w={"50%"}
                    textAlign={"center"}
                    borderColor={theme.black}
                    borderWidth={"1px"}
                    borderRadius={"10px"}
                    p={"10px"}
                    cursor={"pointer"}
                    onClick={onBack}
                >
                    {"رجوع"}
                </Chakra.Text>

            </Chakra.Flex>

        </ChakraUI.Flex>
    );
}

const ShoppingAdPreview = () => {

    const {isOpen, onOpen, onClose} = useDisclosure()

    const dispatch = useDispatch<ThunkDispatch<RootStore, any, AnyAction>>()
    const adState = useSelector((state: RootStore) => state.adReducer)

    const onJoin = () => {
        window.open("https://forms.gle/226GF7JaHmzSKqC36", '_blank')
    }

    const onCancel = () => {
        const new_ad: AdDetailType = {
            ...adState.ad,
            shopping_ad: undefined
        }
        dispatch(UpdateLocalAdAction(new_ad))
        dispatch(DeselectCreatorAction())
    }

    return (
        <ChakraUI.Flex flexDir={"column"} gap={"10"} px={"20px"}>

            <ShoppingAdCard/>

            <Chakra.Flex flexDir={"row"} w={"100%"} gap={"5"}>

                <Chakra.Text
                    w={"50%"}
                    textAlign={"center"}
                    bgColor={theme.light_green}
                    borderColor={theme.black}
                    borderWidth={"1px"}
                    borderRadius={"10px"}
                    p={"10px"}
                    cursor={"pointer"}
                    onClick={onOpen}
                >
                    {"نشر الإعلان"}
                </Chakra.Text>

                <ChakraUI.Modal isCentered isOpen={isOpen} onClose={onClose}>
                    <ChakraUI.ModalOverlay
                        bg='blackAlpha.300'
                        backdropFilter='blur(15px)'
                    />
                    <ChakraUI.ModalContent mx={"30px"}>
                        <ChakraUI.ModalHeader>{"انضم لقائمة الانتظار"}</ChakraUI.ModalHeader>
                        <ChakraUI.ModalBody>
                            <ChakraUI.Flex flexDir={"column"} gap={"3"} mb={"20px"}>

                                <Chakra.Text textAlign={"center"}>
                                    {"سيتم إطلاق التطبيق قريباً بأمكانك التسجيل للانضمام لقائمة الانتظار وستكون أول المدعون لاستخدام التطبيق وإطلاق حملتك الإعلانية الأولى"}
                                </Chakra.Text>

                                <ChakraUI.Flex mt={"10px"} flexDir={"row"} justifyContent={"space-between"}>

                                    <ChakraUI.Button
                                        bgColor={theme.light_green}
                                        borderWidth={"1px"}
                                        borderRadius={"5px"}
                                        borderColor={theme.black}
                                        _hover={{}}
                                        gap={"2"}
                                        onClick={onJoin}
                                    >
                                        <ChakraUI.Text color={theme.black} fontWeight={"500"}>{"تسجيل"}</ChakraUI.Text>
                                    </ChakraUI.Button>

                                    <ChakraUI.Button
                                        bgColor={theme.white}
                                        borderWidth={"1px"}
                                        borderRadius={"5px"}
                                        borderColor={theme.black}
                                        _hover={{}}
                                        gap={"2"}
                                        onClick={onClose}
                                    >
                                        <ChakraUI.Text color={theme.black} fontWeight={"500"}>{"الغاء"}</ChakraUI.Text>
                                    </ChakraUI.Button>

                                </ChakraUI.Flex>
                            </ChakraUI.Flex>
                        </ChakraUI.ModalBody>
                    </ChakraUI.ModalContent>
                </ChakraUI.Modal>

                <Chakra.Text
                    w={"50%"}
                    textAlign={"center"}
                    borderColor={theme.black}
                    borderWidth={"1px"}
                    borderRadius={"10px"}
                    p={"10px"}
                    cursor={"pointer"}
                    onClick={onCancel}
                >
                    {"إلغاء"}
                </Chakra.Text>

            </Chakra.Flex>

        </ChakraUI.Flex>
    );
}

const ShoppingAdCard = () => {

    const adState = useSelector((state: RootStore) => state.adReducer)

    return (
        <ChakraUI.Flex
            flexDir={"column"}
            bgColor={"white"}
            borderRadius={"10px"}
            borderWidth={"1px"}
            borderColor={"#dcdcdc"}
            p={"20px"}
            gap={"0.5"}
        >
            <ChakraUI.Image mb={"10px"} src={product_img}/>
            <ChakraUI.Text color={"#1458d6"} noOfLines={2}>{adState.ad?.shopping_ad!!.title}</ChakraUI.Text>
            <ChakraUI.Text color={theme.black} fontWeight={"bold"}>{adState.ad?.shopping_ad!!.price}</ChakraUI.Text>
            <ChakraUI.Text color={"green"} fontWeight={"bold"}>{adState.ad?.shopping_ad!!.brand}</ChakraUI.Text>
        </ChakraUI.Flex>
    )
}

