import React from 'react';
import * as Chakra from "@chakra-ui/react";
import {theme} from "../../../Utils/Theme";

const reports = [
    {name: "النقرات", count: "14"},
    {name: "مرات الظهور", count: "87"},
    {name: "م. تكلفة النقرة", count: "5.35", currency: "ريال"},
    {name: "التكلفة", count: "50", currency: "ريال"},
]

export const ReportPage = () => {

    return (
        <Chakra.Flex flexDir={"column"} gap={"3"}>

            <Chakra.Text
                textAlign={"center"}
                fontSize={"20px"}
                fontWeight={"bold"}
                mb={"10px"}
            >{"تقارير الإعلان"}</Chakra.Text>

            <Chakra.Flex
                w={"300px"}
                flexDir={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                bgColor={theme.light_green}
                borderColor={theme.black}
                borderWidth={"1px"}
                borderRadius={"10px"}
                p={"15px"}
                cursor={"pointer"}
            >
                <Chakra.Text>{"رصيد الإعلان"}</Chakra.Text>
                <Chakra.Text>
                    <span style={{fontWeight: "bold", fontSize: "20px"}}>{`100 `}</span>
                    {"ريال"}
                </Chakra.Text>
            </Chakra.Flex>

            <Chakra.SimpleGrid columns={[2, null, 2]} spacing='10px' w={"300px"}>
                {reports.map(report => (
                    <Chakra.Flex
                        key={report.name}
                        flexDir={"column"}
                        textAlign={"center"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={"2"}
                        bgColor={theme.light_green}
                        borderColor={theme.black}
                        borderWidth={"1px"}
                        borderRadius={"10px"}
                        p={"10px"}
                        cursor={"pointer"}
                    >
                        <Chakra.Text>{report.name}</Chakra.Text>
                        <Chakra.Text>
                            <span style={{fontWeight: "bold", fontSize: "25px"}}>{`${report.count} `}</span>
                            {report.currency}
                        </Chakra.Text>
                    </Chakra.Flex>
                ))}
            </Chakra.SimpleGrid>

        </Chakra.Flex>
    );
}
