
export const INVITATION_CHECK_REQUEST = "INVITATION_CHECK_REQUEST"
export const INVITATION_CHECK_SUCCESS = "INVITATION_CHECK_SUCCESS"
export const INVITATION_CHECK_ERROR = "INVITATION_CHECK_ERROR"

export type InvitationType = {
    first_name: string,
    code: string,
    access: boolean,
}

export type InvitationRequest = {
    invitation: InvitationType,
}

export type InvitationResponse = {
    status: number,
    message: string,
    invitation: InvitationType,
}

export interface InvitationCheckRequest {
    type: typeof INVITATION_CHECK_REQUEST
}

export interface InvitationCheckSuccess {
    type: typeof INVITATION_CHECK_SUCCESS,
    invitation_response: InvitationResponse
}

export interface InvitationCheckError {
    type: typeof INVITATION_CHECK_ERROR,
    invitation_response: InvitationResponse
}

export type InvitationDispatchTypes =
    | InvitationCheckRequest
    | InvitationCheckSuccess
    | InvitationCheckError

export const InvitationCheckRequestAction = (): InvitationDispatchTypes => {
    return {
        type: INVITATION_CHECK_REQUEST
    }
}

export const InvitationCheckSuccessAction = (invitation_response: InvitationResponse): InvitationDispatchTypes => {
    return {
        type: INVITATION_CHECK_SUCCESS,
        invitation_response: invitation_response
    }
}

export const InvitationCheckErrorAction = (invitation_response: InvitationResponse): InvitationDispatchTypes => {
    return {
        type: INVITATION_CHECK_ERROR,
        invitation_response: invitation_response
    }
}
