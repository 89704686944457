import React from 'react';
import * as Chakra from "@chakra-ui/react";
import {theme} from "../../../Utils/Theme";

const supports = [
    {
        name: "إرسل إلى الايميل",
        description: "رد خلال أيام"
    },
    {
        name: "أرسل إلى الواتساب",
        description: "رد خلال 24 ساعة"
    },
    {
        name: "تحدث عبر مكالمة أونلاين",
        description: "رد خلال ساعة"
    },
    {
        name: "تحدث عبر الهاتف",
        description: "رد فوري"
    }
]

export const SupportPage = () => {
    return (
        <Chakra.Center flexDir={"column"} gap={"2"}>

            <Chakra.Text
                textAlign={"center"}
                fontSize={"20px"}
                fontWeight={"bold"}
                mb={"10px"}
            >{"تحدث مع الدعم"}</Chakra.Text>

            <Chakra.Flex
                w={"300px"}
                flexDir={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                bgColor={theme.light_green}
                borderColor={theme.black}
                borderWidth={"1px"}
                borderRadius={"10px"}
                p={"15px"}
                cursor={"pointer"}
            >
                <Chakra.Text>{"خطة الدعم"}</Chakra.Text>
                <Chakra.Text fontWeight={"bold"}>{"الذهبية"}</Chakra.Text>
            </Chakra.Flex>

            <Chakra.SimpleGrid columns={[2, null, 2]} spacing='10px' w={"300px"}>
                {supports.map(support => (
                    <Chakra.Flex
                        key={support.name}
                        flexDir={"column"}
                        textAlign={"center"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={"2"}
                        bgColor={theme.light_green}
                        borderColor={theme.black}
                        borderWidth={"1px"}
                        borderRadius={"10px"}
                        p={"10px"}
                        cursor={"pointer"}
                    >
                        <Chakra.Text fontSize={"17px"} fontWeight={"bold"}>{support.name}</Chakra.Text>
                        <Chakra.Text>{support.description}</Chakra.Text>
                    </Chakra.Flex>
                ))}
            </Chakra.SimpleGrid>

        </Chakra.Center>
    );
}
