import React from 'react';
import * as Chakra from "@chakra-ui/react";
import {theme} from "../../../Utils/Theme";
import {MdKeyboardArrowLeft} from "react-icons/md";

const transactions = [
    {
        title: "تكلفة النقرات",
        amount: "50 ريال"
    },
    {
        title: "شحن رصيد الإعلان",
        amount: "150 ريال"
    },
    {
        title: "تكلفة النقرات",
        amount: "25 ريال"
    },
]

export const WalletPage = () => {
    return (
        <Chakra.Flex flexDir={"column"} gap={"3"}>

            <Chakra.Text
                textAlign={"center"}
                fontSize={"20px"}
                fontWeight={"bold"}
                mb={"10px"}
            >{"المحفظة والرصيد"}</Chakra.Text>

            <Chakra.Flex
                flexDir={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                bgColor={theme.light_green}
                borderColor={theme.black}
                borderWidth={"1px"}
                borderRadius={"10px"}
                p={"15px"}
                cursor={"pointer"}
            >
                <Chakra.Text>{"رصيد الإعلان"}</Chakra.Text>
                <Chakra.Text>
                    <span style={{fontWeight: "bold", fontSize: "20px"}}>{`100 `}</span>
                    {"ريال"}
                </Chakra.Text>
            </Chakra.Flex>

            <Chakra.Center
                w={"300px"}
                flexDir={"row"}
                textAlign={"center"}
                bgColor={theme.black}
                borderRadius={"10px"}
                py={"10px"}
                px={"15px"}
                cursor={"pointer"}
            >
                <Chakra.Text
                    color={theme.light_green}
                    textAlign={"center"}
                >
                    {"اشحن"}
                </Chakra.Text>
            </Chakra.Center>

            {transactions.map(transaction => (
                <Chakra.Flex
                    key={transaction.title}
                    flexDir={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    borderWidth={"1px"}
                    borderRadius={"10px"}
                    p={"12px"}
                    cursor={"pointer"}
                    bgColor={theme.white}
                    borderColor={theme.black}
                >
                    <Chakra.Text>{transaction.title}</Chakra.Text>
                    <Chakra.Flex flexDir={"row"} gap={"2"} alignItems={"center"}>
                        <Chakra.Text>{transaction.amount}</Chakra.Text>
                        <MdKeyboardArrowLeft size={"20px"}/>
                    </Chakra.Flex>
                </Chakra.Flex>
            ))}

        </Chakra.Flex>
    );
}
