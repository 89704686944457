import * as ChakraUI from "@chakra-ui/react";
import {theme} from "../../Utils/Theme";
import {TiThMenu} from "react-icons/ti";
import {home_info, social_channels} from "../../Utils/Info";
import logo from "../../Assets/logo.svg";
import React from "react";
import {MdKeyboardArrowLeft} from "react-icons/md";
import {useDisclosure} from "@chakra-ui/react";

export const Header = () => {

    const {isOpen, onOpen, onClose} = useDisclosure()

    return (
        <ChakraUI.Flex
            flexDir={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            p={"20px"}
        >

            <ChakraUI.Flex
                flexDir={"row"}
                alignItems={"center"}
                gap={"2"}
                cursor={"pointer"}
            >

                <ChakraUI.Center
                    w={"40px"}
                    h={"40px"}
                    bgColor={theme.light_green}
                    borderWidth={"1px"}
                    borderColor={"black"}
                    borderRadius={"10px"}
                >
                    <ChakraUI.Image src={logo} w={"50px"} h={"50px"}/>
                </ChakraUI.Center>

                <ChakraUI.Text fontSize={"20px"} fontWeight={"bold"}>{home_info.name}</ChakraUI.Text>

            </ChakraUI.Flex>

            <ChakraUI.Flex>
                <ChakraUI.Button
                    colorScheme='none'
                    bgColor={theme.light_green}
                    color={"black"}
                    borderWidth={"1px"}
                    borderColor={"black"}
                    borderRadius={"10px"}
                    onClick={onOpen}
                >
                    <TiThMenu fontSize={"20px"}/>
                </ChakraUI.Button>
                <ChakraUI.Drawer
                    isOpen={isOpen}
                    placement='left'
                    onClose={onClose}
                >
                    <ChakraUI.DrawerOverlay/>
                    <ChakraUI.DrawerContent>
                        <ChakraUI.DrawerCloseButton/>
                        <ChakraUI.DrawerBody>
                            <ChakraUI.Flex flexDir={"column"} alignItems={"center"} gap={"5"} py={"50px"}>
                                {social_channels.map((channel, index) => {
                                    return (
                                        <ChakraUI.Flex
                                            key={index}
                                            w={"100%"}
                                            flexDir={"row"}
                                            justifyContent={"space-between"}
                                            alignItems={"center"}
                                            borderWidth={"1px"}
                                            borderRadius={"10px"}
                                            py={"10px"}
                                            px={"15px"}
                                            cursor={"pointer"}
                                            bgColor={theme.white}
                                            borderColor={theme.gray}
                                            onClick={() => window.open(channel.url, '_blank')}
                                        >
                                            <ChakraUI.Flex flexDir={"row"} alignItems={"center"} gap={"2"}>
                                                <ChakraUI.Icon as={channel.icon} fontSize={"25px"}/>
                                                <ChakraUI.Text noOfLines={1}>{channel.name}</ChakraUI.Text>
                                            </ChakraUI.Flex>
                                            <MdKeyboardArrowLeft size={"20px"}/>
                                        </ChakraUI.Flex>
                                    )
                                })}
                            </ChakraUI.Flex>
                        </ChakraUI.DrawerBody>
                    </ChakraUI.DrawerContent>
                </ChakraUI.Drawer>
            </ChakraUI.Flex>

        </ChakraUI.Flex>
    )
}
