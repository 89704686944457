import {FaLinkedin, FaSquareFacebook, FaSquareInstagram, FaSquareXTwitter, FaTiktok, FaYoutube} from "react-icons/fa6";
import tarwiiga_app_demo from "../Assets/tarwiiga_app_demo.mp4";

export const social_channels = [
    {
        name: "تويتر | Twitter",
        icon: FaSquareXTwitter,
        url: "https://x.com/TarwiigaApp",
    },
    {
        name: "فيسبوك | Facebook",
        icon: FaSquareFacebook,
        url: "https://facebook.com/TarwiigaApp",
    },
    {
        name: "إنستغرام | Instagram",
        icon: FaSquareInstagram,
        url: "https://instagram.com/TarwiigaApp",
    },
    {
        name: "لينكدان | Linkedin",
        icon: FaLinkedin,
        url: "https://linkedin.com/company/TarwiigaApp",
    },
    {
        name: "يوتيوب | Youtube",
        icon: FaYoutube,
        url: "https://youtube.com/@TarwiigaApp",
    },
    {
        name: "تيك توك | TikTok",
        icon: FaTiktok,
        url: "https://tiktok.com/@TarwiigaApp",
    },
]

export const home_info = {
    name: "تـرويـجـة",
    headline1: "إعلانك على جوجل بالذكاء الاصطناعي",
    headline2: "احصل على مكالمات لخدمتك، أو مبيعات لمنتجك، أو زيارات لموقعك، أو تنزيلات لتطبيقك",
    description: "واجهة بسيطة ومباشرة تتيح لك إنشاء ونشر إعلان جوجل في دقائق",
    demo_video: tarwiiga_app_demo,
}

export const steps = [
    {
        id: "watch",
        title: "خطوة ١",
        description: "شاهد الفيديو الدعائي لمعرفة كيف يعمل التطبيق",
        call_to_action_title: "شاهد الفيديو",
        call_to_action_url: "https://app.tarwiiga.com/static/media/tarwiiga_app_demo.bb52204d659e6444995a.mp4",
    },
    // {
    //     id: "discover",
    //     title: "خطوة ٢",
    //     description: "استكشف واجهة التطبيق لتتعرف على مميزاته",
    //     call_to_action_title: "استكشف التطبيق",
    //     call_to_action_url: "",
    // },
    {
        id: "join",
        title: "خطوة ٢",
        description: "انضم لقائمة الانتظار وسيتم إرسال دعوة لبريدك الإلكتروني مع رمز الدخول",
        call_to_action_title: "انضم لقائمة الانتظار",
        call_to_action_url: "https://forms.gle/Hv1CMf682YAhhWFaA",
    },
    {
        id: "try",
        title: "خطوة ٣",
        description: "إذا حصلت على دعوة ورمز الدخول تقدر تسجل وتجرب التطبيق الآن",
        call_to_action_title: "جرب التطبيق الآن",
        call_to_action_url: "",
    },
]
