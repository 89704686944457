import {As} from "@chakra-ui/react";

export const PAGE_SELECT = "PAGE_SELECT"

export type PageType = {
    id: string,
    name: string,
    icon: As,
}

export interface SelectPage {
    type: typeof PAGE_SELECT,
    page: PageType
}

export type PageDispatchTypes = SelectPage

export const SelectPageAction = (page: PageType): PageDispatchTypes => {
    return {
        type: PAGE_SELECT,
        page: page,
    }
}