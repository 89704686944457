
export const APP_SELECT = "APP_SELECT"

export interface SelectApp {
    type: typeof APP_SELECT,
    app: string
}

export type AppDispatchTypes = SelectApp

export const SelectAppAction = (app: string): AppDispatchTypes => {
    return {
        type: APP_SELECT,
        app: app,
    }
}