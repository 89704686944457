import {As} from "@chakra-ui/react";

export const CREATOR_SELECT = "CREATOR_SELECT"
export const CREATOR_DESELECT = "CREATOR_DESELECT"

export type CreatorType = {
    id: string,
    name: string,
    description: string,
    icon: As,
}

export interface SelectCreator {
    type: typeof CREATOR_SELECT,
    creator: CreatorType
}

export interface DeselectCreator {
    type: typeof CREATOR_DESELECT
}

export type CreatorDispatchTypes = SelectCreator | DeselectCreator

export const SelectCreatorAction = (creator: CreatorType): CreatorDispatchTypes => {
    return {
        type: CREATOR_SELECT,
        creator: creator,
    }
}

export const DeselectCreatorAction = (): CreatorDispatchTypes => {
    return {
        type: CREATOR_DESELECT,
    }
}
