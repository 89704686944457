
export const AD_CREATE_REQUEST = "AD_CREATE_REQUEST"
export const AD_CREATE_SUCCESS = "AD_CREATE_SUCCESS"
export const AD_CREATE_ERROR = "AD_CREATE_ERROR"

export const UPDATE_LOCAL_AD = "UPDATE_LOCAL_AD"

export type AdType = {
    headlines: string[],
    descriptions: string[],
}

export type ShoppingAdType = {
    title: string,
    price: string,
    brand: string,
}

export type AdDetailType = {

    ad_type?: string,

    business_country?: string,
    business_phone_number?: string,
    business_name?: string,
    business_description?: string,
    business_city?: string,
    business_ad_time?: string,
    business_website?: string,

    product_image?: string,
    product_url?: string,
    product_title?: string,
    product_description?: string,
    product_price?: string,
    product_brand?: string,

    app_url?: string,
    app_name?: string,
    app_description?: string,

    call_ad?: AdType,
    responsive_ad?: AdType,
    shopping_ad?: ShoppingAdType,
    app_ad?: AdType,
}

export type AdRequest = {
    ad: AdDetailType,
}

export type AdResponse = {
    status: number,
    message: string,
    ad: AdDetailType,
}

export interface AdCreateRequest {
    type: typeof AD_CREATE_REQUEST
}

export interface AdCreateSuccess {
    type: typeof AD_CREATE_SUCCESS,
    ad_response: AdResponse
}

export interface AdCreateError {
    type: typeof AD_CREATE_ERROR,
    ad_response: AdResponse
}

export interface UpdateLocalAd {
    type: typeof UPDATE_LOCAL_AD,
    ad: AdDetailType
}

export type AdDispatchTypes =
    | AdCreateRequest
    | AdCreateSuccess
    | AdCreateError
    | UpdateLocalAd

export const AdCreateRequestAction = (): AdDispatchTypes => {
    return {
        type: AD_CREATE_REQUEST
    }
}

export const AdCreateSuccessAction = (ad_response: AdResponse): AdDispatchTypes => {
    return {
        type: AD_CREATE_SUCCESS,
        ad_response: ad_response
    }
}

export const AdCreateErrorAction = (ad_response: AdResponse): AdDispatchTypes => {
    return {
        type: AD_CREATE_ERROR,
        ad_response: ad_response
    }
}

export const UpdateLocalAdAction = (ad: AdDetailType): AdDispatchTypes => {
    return {
        type: UPDATE_LOCAL_AD,
        ad: ad
    }
}
