import React from "react";
import * as ChakraUI from "@chakra-ui/react";
import {home_info, social_channels} from "../../Utils/Info";
import {theme} from "../../Utils/Theme";

export const Footer = () => {
    return (
        <ChakraUI.Center
            flexDir={"row"}
            bgColor={theme.light_green}
            p={"10px"}
            gap={"5"}
            borderTopWidth={"1px"}
            borderColor={"black"}
            width={"100%"}
            position={"fixed"}
            bottom={0}
            zIndex={10}
        >
            {`${home_info.name} © 2024`}
        </ChakraUI.Center>
    )
}
