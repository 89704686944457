import {CreatorType} from "../Data/Creator/Actions";
import {PiPhoneCallFill} from "react-icons/pi";
import {FaDownload, FaGlobe, FaShoppingBag} from "react-icons/fa";

export const CREATOR = {
    CALL_AD_CREATOR: "Call Ad Creator",
    RESPONSIVE_AD_CREATOR: "Responsive Ad Creator",
    SHOPPING_AD_CREATOR: "Shopping Ad Creator",
    APP_AD_CREATOR: "Main Ad Creator",
}

export const creators: CreatorType[] = [
    {
        id: CREATOR.CALL_AD_CREATOR,
        name: "إعلان إتصال بالهاتف",
        description: "",
        icon: PiPhoneCallFill,
    },
    {
        id: CREATOR.RESPONSIVE_AD_CREATOR,
        name: "إعلان زيارة الموقع الإلكتروني",
        description: "",
        icon: FaGlobe,
    },
    {
        id: CREATOR.SHOPPING_AD_CREATOR,
        name: "إعلان بيع المنتجات",
        description: "",
        icon: FaShoppingBag,
    },
    {
        id: CREATOR.APP_AD_CREATOR,
        name: "إعلان تنزيل التطبيق",
        description: "",
        icon: FaDownload,
    },
]

export const getCreator = (id: string) => {
    const index = creators.findIndex(creator => creator.id === id)
    return creators[index]
}
