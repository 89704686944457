import {
    INVITATION_CHECK_ERROR,
    INVITATION_CHECK_REQUEST,
    INVITATION_CHECK_SUCCESS,
    InvitationDispatchTypes,
    InvitationType,
} from "./Actions";

interface InvitationState {
    invitation: InvitationType,
    status: number,
    is_check_invitation_loading: boolean,
    check_invitation_success_message: string,
    check_invitation_error_message: string,
}

const defaultState: InvitationState = {
    invitation: {
        first_name: "",
        code: "",
        access: false
    },
    status: 0,
    is_check_invitation_loading: false,
    check_invitation_success_message: "",
    check_invitation_error_message: "",
}

export const InvitationReducer = (
    state: InvitationState = defaultState, action: InvitationDispatchTypes
): InvitationState => {
    switch (action.type) {
        case INVITATION_CHECK_REQUEST:
            return {
                ...state,
                is_check_invitation_loading: true,
            }
        case INVITATION_CHECK_SUCCESS:
            return {
                ...state,
                is_check_invitation_loading: false,
                status: action.invitation_response.status,
                check_invitation_success_message: action.invitation_response.message,
                check_invitation_error_message: "",
                invitation: action.invitation_response.invitation,
            }
        case INVITATION_CHECK_ERROR:
            return {
                ...state,
                is_check_invitation_loading: false,
                status: action.invitation_response.status,
                check_invitation_success_message: "",
                check_invitation_error_message: action.invitation_response.message,
            }
        default:
            return state
    }
}

