import React from 'react';
import {useSelector} from "react-redux";
import {RootStore} from "../../../../Data/Store";
import {CREATOR} from "../../../../Utils/Creator";
import {CallAdCreator} from "./CallAdCreator";
import {ResponsiveAdCreator} from "./ResponsiveAdCreator";
import {ShoppingAdCreator} from "./ShoppingAdCreator";
import * as Chakra from "@chakra-ui/react";
import {AppAdCreator} from "./AppAdCreator";

export const AdCreator = () => {

    const creatorState = useSelector((state: RootStore) => state.creatorReducer)

    const RenderCreator = () => {
        switch (creatorState.creator?.id) {
            case CREATOR.CALL_AD_CREATOR:
                return <CallAdCreator/>
            case CREATOR.RESPONSIVE_AD_CREATOR:
                return <ResponsiveAdCreator/>
            case CREATOR.SHOPPING_AD_CREATOR:
                return <ShoppingAdCreator/>
            case CREATOR.APP_AD_CREATOR:
                return <AppAdCreator/>
            default:
                return <CallAdCreator/>
        }
    }

    return (
        <Chakra.Center flexDir={"column"} gap={"3"}>
            {RenderCreator()}
        </Chakra.Center>
    )
}
