import React from 'react';
import * as ChakraUI from "@chakra-ui/react";
import {theme} from "../../../../Utils/Theme";
import {IoIosArrowDown} from "react-icons/io";
import {AD_TYPES, CountryType, countries, getCountry} from "../../../../Utils/Types";
import * as Chakra from "@chakra-ui/react";
import {MdPhone} from "react-icons/md";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../Data/Store";
import {RiEarthFill} from "react-icons/ri";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {AdRequest, AdDetailType, UpdateLocalAdAction} from "../../../../Data/Ad/Actions";
import {CreateAdAction} from "../../../../Data/Ad/Helpers";
import {DeselectCreatorAction} from "../../../../Data/Creator/Actions";
import {useDisclosure} from "@chakra-ui/react";

export const CallAdCreator = () => {

    const adState = useSelector((state: RootStore) => state.adReducer)

    return (
        <>
            {adState.is_create_ad_loading
                ?
                <ChakraUI.Center w={"100%"}>
                    <ChakraUI.CircularProgress
                        isIndeterminate
                        size={70}
                        color={theme.light_green}
                        trackColor={theme.black}
                    />
                </ChakraUI.Center>
                :
                <>
                    {adState.ad?.call_ad === undefined
                        ? <CallAdEditor/>
                        : <CallAdPreview/>
                    }
                </>
            }
        </>
    );
}

const CallAdEditor = () => {

    const initial_ad: AdDetailType = {
        ad_type: AD_TYPES.CALL_AD,
        business_country: "SA",
        business_phone_number: "0561234567",
        business_name: "ترويجة",
        business_description: "تطبيق لصنع إعلانات جوجل بالذكاء الاصطناعي",
        business_city: "الرياض",
        business_ad_time: "كل يوم من ١٠ ص ل ٦ م",
    }

    const dispatch = useDispatch<ThunkDispatch<RootStore, any, AnyAction>>()
    const [ad, setAd] = React.useState<AdDetailType>(initial_ad)
    const [businessCountryError, setBusinessCountryError] = React.useState("")
    const [businessPhoneNumberError, setBusinessPhoneNumberError] = React.useState("")
    const [businessNameError, setBusinessNameError] = React.useState("")
    const [businessDescriptionError, setBusinessDescriptionError] = React.useState("")
    const [businessCityError, setBusinessCityError] = React.useState("")
    const [businessAdTimeError, setBusinessAdTimeError] = React.useState("")

    const onCreate = () => {
        if (ad.business_country === "") {
            setBusinessCountryError("Error")
        }
        if (ad.business_phone_number === "") {
            setBusinessPhoneNumberError("Error")
        }
        if (ad.business_name === "") {
            setBusinessNameError("Error")
        }
        if (ad.business_description === "") {
            setBusinessDescriptionError("Error")
        }
        if (ad.business_city === "") {
            setBusinessCityError("Error")
        }
        if (ad.business_ad_time === "") {
            setBusinessAdTimeError("Error")
        }
        else {
            const ad_request: AdRequest = {ad: ad}
            dispatch(CreateAdAction(ad_request))
        }
    }

    const onBack = () => {
        dispatch(DeselectCreatorAction())
    }

    return (
        <ChakraUI.Flex flexDir={"column"} gap={"10"}>

            <ChakraUI.Flex flexDir={"column"} gap={"5"}>

                <ChakraUI.Flex flexDir={"column"} gap={"2"} width={"300px"}>
                    <ChakraUI.Text>{`الدولة`}</ChakraUI.Text>
                    <ChakraUI.Menu>
                        <ChakraUI.MenuButton
                            px={4}
                            py={2}
                            transition='all 0.2s'
                            borderRadius='5px'
                            borderWidth='1px'
                            _hover={{}}
                            _expanded={{}}
                            _focus={{}}
                            borderColor={businessCountryError !== "" ? "red" : theme.black}
                            bgColor={theme.white}
                            w={"300px"}
                        >
                            <ChakraUI.Flex flexDir={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                <ChakraUI.Text>{ad.business_country !== "" ? getCountry(ad.business_country!!).name : "اختر الدولة"}</ChakraUI.Text>
                                <IoIosArrowDown/>
                            </ChakraUI.Flex>
                        </ChakraUI.MenuButton>
                        <ChakraUI.MenuList w={"300px"}>
                            {countries.map((country: CountryType) => {
                                return (
                                    <ChakraUI.MenuItem
                                        key={country.code}
                                        onClick={() => {
                                            setAd({...ad!!, business_country: country.code})
                                            setBusinessCountryError("")
                                        }}
                                    >
                                        {country.name}
                                    </ChakraUI.MenuItem>
                                )
                            })}
                        </ChakraUI.MenuList>
                    </ChakraUI.Menu>
                </ChakraUI.Flex>

                <ChakraUI.Flex flexDir={"column"} gap={"2"} width={"300px"}>
                    <ChakraUI.Text>{`رقم الهاتف`}</ChakraUI.Text>
                    <ChakraUI.Input
                        type={"number"}
                        value={ad.business_phone_number}
                        focusBorderColor={theme.black}
                        color={theme.black}
                        borderColor={businessPhoneNumberError !== "" ? "red" : theme.black}
                        _hover={{}}
                        onChange={e => {
                            setAd({
                                ...ad!!,
                                business_phone_number: e.target.value
                            })
                            setBusinessPhoneNumberError("")
                        }}
                    />
                </ChakraUI.Flex>

                <ChakraUI.Flex flexDir={"column"} gap={"2"} width={"300px"}>
                    <ChakraUI.Text>{`اسم نشاطك التجاري`}</ChakraUI.Text>
                    <ChakraUI.Input
                        type={"text"}
                        value={ad.business_name}
                        focusBorderColor={theme.black}
                        color={theme.black}
                        borderColor={businessNameError !== "" ? "red" : theme.black}
                        _hover={{}}
                        onChange={e => {
                            setAd({
                                ...ad!!,
                                business_name: e.target.value
                            })
                            setBusinessNameError("")
                        }}
                    />
                </ChakraUI.Flex>

                <ChakraUI.Flex flexDir={"column"} gap={"2"} width={"300px"}>
                    <ChakraUI.Text>{`وصف نشاطك التجاري`}</ChakraUI.Text>
                    <ChakraUI.Textarea
                        value={ad.business_description}
                        focusBorderColor={theme.black}
                        color={theme.black}
                        borderColor={businessDescriptionError !== "" ? "red" : theme.black}
                        _hover={{}}
                        onChange={e => {
                            setAd({
                                ...ad!!,
                                business_description: e.target.value
                            })
                            setBusinessDescriptionError("")
                        }}
                    />
                </ChakraUI.Flex>

                <ChakraUI.Flex flexDir={"column"} gap={"2"} width={"300px"}>
                    <ChakraUI.Text>{`مدينة عرض الاعلان`}</ChakraUI.Text>
                    <ChakraUI.Menu>
                        <ChakraUI.MenuButton
                            px={4}
                            py={2}
                            transition='all 0.2s'
                            borderRadius='5px'
                            borderWidth='1px'
                            _hover={{}}
                            _expanded={{}}
                            _focus={{}}
                            borderColor={businessCityError !== "" ? "red" : theme.black}
                            bgColor={theme.white}
                            w={"300px"}
                        >
                            <ChakraUI.Flex flexDir={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                <ChakraUI.Text>{ad.business_city !== "" ? ad.business_city : "اختر المدينة"}</ChakraUI.Text>
                                <IoIosArrowDown/>
                            </ChakraUI.Flex>
                        </ChakraUI.MenuButton>
                        <ChakraUI.MenuList w={"300px"}>
                            {["الرياض"].map(city => {
                                return (
                                    <ChakraUI.MenuItem
                                        key={city}
                                        onClick={() => {
                                            setAd({...ad!!, business_city: city})
                                            setBusinessCityError("")
                                        }}
                                    >
                                        {city}
                                    </ChakraUI.MenuItem>
                                )
                            })}
                        </ChakraUI.MenuList>
                    </ChakraUI.Menu>
                </ChakraUI.Flex>

                <ChakraUI.Flex flexDir={"column"} gap={"2"} width={"300px"}>
                    <ChakraUI.Text>{`توقيت عرض الإعلان`}</ChakraUI.Text>
                    <ChakraUI.Menu>
                        <ChakraUI.MenuButton
                            px={4}
                            py={2}
                            transition='all 0.2s'
                            borderRadius='5px'
                            borderWidth='1px'
                            _hover={{}}
                            _expanded={{}}
                            _focus={{}}
                            borderColor={businessAdTimeError !== "" ? "red" : theme.black}
                            bgColor={theme.white}
                            w={"300px"}
                        >
                            <ChakraUI.Flex flexDir={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                <ChakraUI.Text>{ad.business_ad_time !== "" ? ad.business_ad_time : "اختر التوقيت"}</ChakraUI.Text>
                                <IoIosArrowDown/>
                            </ChakraUI.Flex>
                        </ChakraUI.MenuButton>
                        <ChakraUI.MenuList w={"300px"}>
                            {["كل يوم من ١٠ ص ل ٦ م"].map(time => {
                                return (
                                    <ChakraUI.MenuItem
                                        key={time}
                                        onClick={() => {
                                            setAd({...ad!!, business_ad_time: time})
                                            setBusinessAdTimeError("")
                                        }}
                                    >
                                        {time}
                                    </ChakraUI.MenuItem>
                                )
                            })}
                        </ChakraUI.MenuList>
                    </ChakraUI.Menu>
                </ChakraUI.Flex>

            </ChakraUI.Flex>

            <Chakra.Flex flexDir={"row"} w={"300px"} gap={"5"}>

                <Chakra.Text
                    w={"50%"}
                    textAlign={"center"}
                    bgColor={theme.light_green}
                    borderColor={theme.black}
                    borderWidth={"1px"}
                    borderRadius={"10px"}
                    p={"10px"}
                    cursor={"pointer"}
                    onClick={onCreate}
                >
                    {"إنشاء"}
                </Chakra.Text>

                <Chakra.Text
                    w={"50%"}
                    textAlign={"center"}
                    borderColor={theme.black}
                    borderWidth={"1px"}
                    borderRadius={"10px"}
                    p={"10px"}
                    cursor={"pointer"}
                    onClick={onBack}
                >
                    {"رجوع"}
                </Chakra.Text>

            </Chakra.Flex>

        </ChakraUI.Flex>
    );
}

const CallAdPreview = () => {

    const {isOpen, onOpen, onClose} = useDisclosure()

    const dispatch = useDispatch<ThunkDispatch<RootStore, any, AnyAction>>()
    const adState = useSelector((state: RootStore) => state.adReducer)

    const onJoin = () => {
        window.open("https://forms.gle/226GF7JaHmzSKqC36", '_blank')
    }

    const onCancel = () => {
        const new_ad: AdDetailType = {
            ...adState.ad,
            call_ad: undefined
        }
        dispatch(UpdateLocalAdAction(new_ad))
        dispatch(DeselectCreatorAction())
    }

    return (
        <ChakraUI.Flex flexDir={"column"} gap={"5"} px={"20px"}>

            <CallAdCard/>

            <Chakra.Flex flexDir={"row"} w={"100%"} gap={"5"}>

                <Chakra.Text
                    w={"50%"}
                    textAlign={"center"}
                    bgColor={theme.light_green}
                    borderColor={theme.black}
                    borderWidth={"1px"}
                    borderRadius={"10px"}
                    p={"10px"}
                    cursor={"pointer"}
                    onClick={onOpen}
                >
                    {"نشر الإعلان"}
                </Chakra.Text>

                <ChakraUI.Modal isCentered isOpen={isOpen} onClose={onClose}>
                    <ChakraUI.ModalOverlay
                        bg='blackAlpha.300'
                        backdropFilter='blur(15px)'
                    />
                    <ChakraUI.ModalContent mx={"30px"}>
                        <ChakraUI.ModalHeader>{"انضم لقائمة الانتظار"}</ChakraUI.ModalHeader>
                        <ChakraUI.ModalBody>
                            <ChakraUI.Flex flexDir={"column"} gap={"3"} mb={"20px"}>

                                <Chakra.Text textAlign={"center"}>
                                    {"سيتم إطلاق التطبيق قريباً بأمكانك التسجيل للانضمام لقائمة الانتظار وستكون أول المدعون لاستخدام التطبيق وإطلاق حملتك الإعلانية الأولى"}
                                </Chakra.Text>

                                <ChakraUI.Flex mt={"10px"} flexDir={"row"} justifyContent={"space-between"}>

                                    <ChakraUI.Button
                                        bgColor={theme.light_green}
                                        borderWidth={"1px"}
                                        borderRadius={"5px"}
                                        borderColor={theme.black}
                                        _hover={{}}
                                        gap={"2"}
                                        onClick={onJoin}
                                    >
                                        <ChakraUI.Text color={theme.black} fontWeight={"500"}>{"تسجيل"}</ChakraUI.Text>
                                    </ChakraUI.Button>

                                    <ChakraUI.Button
                                        bgColor={theme.white}
                                        borderWidth={"1px"}
                                        borderRadius={"5px"}
                                        borderColor={theme.black}
                                        _hover={{}}
                                        gap={"2"}
                                        onClick={onClose}
                                    >
                                        <ChakraUI.Text color={theme.black} fontWeight={"500"}>{"الغاء"}</ChakraUI.Text>
                                    </ChakraUI.Button>

                                </ChakraUI.Flex>
                            </ChakraUI.Flex>
                        </ChakraUI.ModalBody>
                    </ChakraUI.ModalContent>
                </ChakraUI.Modal>

                <Chakra.Text
                    w={"50%"}
                    textAlign={"center"}
                    borderColor={theme.black}
                    borderWidth={"1px"}
                    borderRadius={"10px"}
                    p={"10px"}
                    cursor={"pointer"}
                    onClick={onCancel}
                >
                    {"إلغاء"}
                </Chakra.Text>

            </Chakra.Flex>

        </ChakraUI.Flex>
    );
}

const CallAdCard = () => {

    const adState = useSelector((state: RootStore) => state.adReducer)

    const get_ad_url = (): string => {
        return `sites.tarwiiga.com/google/ads`
    }

    return (
        <ChakraUI.Flex
            flexDir={"column"}
            gap={"10px"}
            bgColor={"white"}
            p={"20px"}
            borderRadius={"10px"}
            borderWidth={"1px"}
            borderColor={"#dcdcdc"}
        >
            <ChakraUI.Text fontWeight={"bold"}>{"إعلان"}</ChakraUI.Text>
            <ChakraUI.Flex flexDir={"row"} gap={"10px"} justifyContent={"start"} alignItems={"center"}>
                <ChakraUI.Center w={"35px"} height={"35px"} borderRadius={"35px"} bgColor={"#e2eeff"}>
                    <RiEarthFill size={"22px"} color={"blue"}/>
                </ChakraUI.Center>
                <ChakraUI.Flex flexDir={"column"}>
                    <ChakraUI.Text fontSize={"16px"}>{adState.ad?.business_name}</ChakraUI.Text>
                    <ChakraUI.Text fontSize={"14px"} dir={"ltr"}>{get_ad_url()}</ChakraUI.Text>
                </ChakraUI.Flex>
            </ChakraUI.Flex>

            <ChakraUI.Flex flexDir={"row"}>
                <ChakraUI.Text color={"#1458d6"} fontSize={"20px"}>
                    {adState.ad?.call_ad!!.headlines.map((headline: string, index: number) => {
                        return (
                            <span key={index}>
                                {headline}
                                {adState.ad?.call_ad!!.headlines.length!! - 1 === index ? "" : " | "}
                            </span>
                        )
                    })}
                </ChakraUI.Text>
            </ChakraUI.Flex>

            <ChakraUI.Flex flexDir={"row"}>
                <ChakraUI.Text maxWidth={"400px"}>
                    {adState.ad?.call_ad!!.descriptions.map((description: string, index: number) => {
                        return (
                            <span key={index}>
                                {description}
                                {adState.ad?.call_ad!!.descriptions.length!! - 1 === index ? "" : " "}
                            </span>
                        )
                    })}
                </ChakraUI.Text>
            </ChakraUI.Flex>

            <ChakraUI.Flex
                flexDir={"row"}
                gap={"10px"}
                justifyContent={"start"}
                alignItems={"center"}
                borderColor={"#f1f3f4"}
                borderWidth={"2px"}
                borderRadius={"10px"}
                p={"10px"}
            >
                <MdPhone size={"30px"} color={"blue"}/>
                <ChakraUI.Flex flexDir={"column"}>
                    <ChakraUI.Text fontSize={"18px"} color={"#1458d6"} fontWeight={"500"}>
                        {`اتصل على ${adState.ad?.business_phone_number}`}
                    </ChakraUI.Text>
                    <ChakraUI.Text fontSize={"14px"}>
                        {adState.ad?.business_name}
                    </ChakraUI.Text>
                </ChakraUI.Flex>
            </ChakraUI.Flex>
        </ChakraUI.Flex>
    )
}
