import {CREATOR_DESELECT, CREATOR_SELECT, CreatorDispatchTypes, CreatorType} from "./Actions";

interface CreatorState {
    creator: CreatorType | null,
}

const defaultState: CreatorState = {
    creator: null,
}

export const CreatorReducer = (state: CreatorState = defaultState, action: CreatorDispatchTypes): CreatorState => {
    switch (action.type) {
        case CREATOR_SELECT:
            return {
                ...state,
                creator: action.creator
            }
        case CREATOR_DESELECT:
            return {
                ...state,
                creator: null
            }
        default:
            return state
    }
}
