export const APP = {
    HOME: "Home",
    APP: "App",
    DISCOVERY_APP: "DiscoveryApp",
}

export const apps: string[] = [
    APP.HOME,
    APP.APP,
    APP.DISCOVERY_APP,
]

export const getApp = (id: string) => {
    const index = apps.findIndex(app => app === id)
    return apps[index]
}
