import React, {useEffect, useState} from 'react';
import * as ChakraUI from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {RootStore} from "../../../Data/Store";
import {theme} from "../../../Utils/Theme";
import {InvitationRequest} from "../../../Data/Invitation/Actions";
import {CheckInvitationAction} from "../../../Data/Invitation/Helpers";
import logo from "../../../Assets/logo.svg";
import {home_info} from "../../../Utils/Info";
import {PageType, SelectPageAction} from "../../../Data/Page/Actions";
import {getPage} from "../../../Utils/Page";

export const LoginPage = () => {

    const [invitationCode, setInvitationCode] = useState<string>("")
    const invitationState = useSelector((state: RootStore) => state.invitationReducer)
    const dispatch = useDispatch<ThunkDispatch<RootStore, any, AnyAction>>()

    useEffect(() => {
        const page: PageType = getPage(localStorage.getItem("page")!!)
        dispatch(SelectPageAction(page))
    }, [invitationState.status]);

    const onInvitationCheck = () => {
        const invitation_request: InvitationRequest = {
            invitation: {
                ...invitationState.invitation,
                code: invitationCode
            }
        }
        dispatch(CheckInvitationAction(invitation_request))
    }

    return (
        <ChakraUI.Flex flexDir={"column"} gap={"3"}>
            {invitationState.is_check_invitation_loading
                ?
                <ChakraUI.Center flexDir={"column"} w={"100%"} minH={"100vh"}>
                    <ChakraUI.CircularProgress
                        isIndeterminate
                        size={70}
                        color={theme.light_green}
                        trackColor={theme.black}
                    />
                </ChakraUI.Center>
                :
                <ChakraUI.Center flexDir={"column"} w={["100%", "70%", "50%", "30%"]} mx={"auto"} minH={"100vh"} gap={"5"} p={"50px"}>

                    <ChakraUI.Flex
                        flexDir={"column"}
                        alignItems={"center"}
                        gap={"2"}
                        cursor={"pointer"}
                    >
                        <ChakraUI.Center
                            w={"40px"}
                            h={"40px"}
                            bgColor={theme.light_green}
                            borderWidth={"1px"}
                            borderColor={"black"}
                            borderRadius={"10px"}
                        >
                            <ChakraUI.Image src={logo} w={"50px"} h={"50px"}/>
                        </ChakraUI.Center>

                        <ChakraUI.Text fontSize={"20px"} fontWeight={"bold"}>{home_info.name}</ChakraUI.Text>

                    </ChakraUI.Flex>

                    {invitationState.check_invitation_error_message !== "" && (
                        <ChakraUI.Text color={"red"}>{invitationState.check_invitation_error_message}</ChakraUI.Text>
                    )}
                    <ChakraUI.Input
                        type={"text"}
                        value={invitationCode}
                        onChange={event => setInvitationCode(event.target.value)}
                        placeholder={"أكتب الرمز المرسل مع الدعوة"}
                        focusBorderColor={theme.black}
                        color={theme.black}
                        borderColor={theme.black}
                        _hover={{}}
                    />
                    <ChakraUI.Button
                        w={"100%"}
                        colorScheme={"none"}
                        bgColor={theme.light_green}
                        color={theme.black}
                        borderRadius={"10px"}
                        borderWidth={"1px"}
                        borderColor={"black"}
                        fontSize={"18px"}
                        fontWeight={"bold"}
                        onClick={onInvitationCheck}
                    >
                        {"دخــول"}
                    </ChakraUI.Button>
                </ChakraUI.Center>
            }
        </ChakraUI.Flex>
    );
}
