import {Dispatch} from "redux";
import {
    AdCreateErrorAction,
    AdCreateRequestAction,
    AdCreateSuccessAction,
    AdDispatchTypes,
    AdResponse,
    AdRequest,
    AdType,
} from "./Actions";
import {GoogleGenerativeAI} from "@google/generative-ai";
import {AD_TYPES} from "../../Utils/Types";

export async function generate_call_ad(business_description: string) {

    const genAI = new GoogleGenerativeAI("AIzaSyAqAjW9_VT1KNjIXbYfFpxp7tf0x7Rekzo");

    const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash"});

    const prompt = `
    You are a google ads specialist, you write ad copies for call-only ads.
    Your ads should be attractive, and make clients click to ask for the services.
    You will be given a a business description about and ad idea and your job is to generate the ad in the language of business description.
    
    Input:
        Business Description: ${business_description}

    Output:
        headlines: list of 2 headlines
        descriptions: list of 2 descriptions
        
    headline limit is 30 characters long.
    description limit is 90 characters long.
    
    Using this JSON schema:
    CallAd = {'headlines': list[str], 'descriptions': list[str]}
    Return: CallAd
    `
    const result = await model.generateContent(prompt);
    const response = result.response;
    const raw_json_response = response.text()
    const raw_json_output = raw_json_response as string
    const json_output = raw_json_output.replace(/```json|```/g, "").trim();
    const generated_call_ad: AdType = JSON.parse(json_output);
    return generated_call_ad;
}

export async function generate_responsive_ad(business_description: string) {

    const genAI = new GoogleGenerativeAI("AIzaSyAqAjW9_VT1KNjIXbYfFpxp7tf0x7Rekzo");

    const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash"});

    const prompt = `
    You are a google ads specialist, you write ad copies for google responsive search ads.
    Your ads should be attractive, and make users click to visit the website.
    You will be given a business description and your job is to generate the ad in the language of business description.
    
    Input:
        Business Description: ${business_description}

    Output:
        headlines: list of 2 headlines
        descriptions: list of 2 descriptions
        
    headline limit is 30 characters long.
    description limit is 90 characters long.
    
    Using this JSON schema:
    ResponsiveAd = {'headlines': list[str], 'descriptions': list[str]}
    Return: ResponsiveAd
    `
    const result = await model.generateContent(prompt);
    const response = result.response;
    const raw_json_response = response.text()
    const raw_json_output = raw_json_response as string
    const json_output = raw_json_output.replace(/```json|```/g, "").trim();
    const generated_responsive_ad: AdType = JSON.parse(json_output);
    console.log(generated_responsive_ad)
    return generated_responsive_ad;
}

export async function generate_app_ad(app_description: string) {

    const genAI = new GoogleGenerativeAI("AIzaSyAqAjW9_VT1KNjIXbYfFpxp7tf0x7Rekzo");

    const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash"});

    const prompt = `
    You are a google ads specialist, you write ad copies for google app install ads.
    Your ads should be attractive, and make users click to install the app.
    You will be given an app description and your job is to generate the ad in the language of business description.
    
    Input:
        App Description: ${app_description}

    Output:
        headlines: list of 2 headlines
        descriptions: list of 2 descriptions
        
    headline limit is 30 characters long.
    description limit is 90 characters long.
    
    Using this JSON schema:
    AppAd = {'headlines': list[str], 'descriptions': list[str]}
    Return: AppAd
    `
    const result = await model.generateContent(prompt);
    const response = result.response;
    const raw_json_response = response.text()
    const raw_json_output = raw_json_response as string
    const json_output = raw_json_output.replace(/```json|```/g, "").trim();
    const generated_app_ad: AdType = JSON.parse(json_output);
    return generated_app_ad;
}

async function generate_ad(ad_request: AdRequest) {
    switch (ad_request.ad.ad_type) {
        case AD_TYPES.CALL_AD:
            const generated_call_ad = await generate_call_ad(ad_request.ad.business_description!!)
            const call_ad_response: AdResponse = {
                status: 200,
                message: "Ad created successfully!",
                ad: {
                    ...ad_request.ad,
                    call_ad: generated_call_ad
                },
            }
            return call_ad_response
        case AD_TYPES.RESPONSIVE_AD:
            const generated_responsive_ad = await generate_responsive_ad(ad_request.ad.business_description!!)
            const responsive_ad_response: AdResponse = {
                status: 200,
                message: "Ad created successfully!",
                ad: {
                    ...ad_request.ad,
                    responsive_ad: generated_responsive_ad
                },
            }
            return responsive_ad_response
        case AD_TYPES.APP_AD:
            const generated_app_ad = await generate_app_ad(ad_request.ad.app_description!!)
            const app_ad_response: AdResponse = {
                status: 200,
                message: "Ad created successfully!",
                ad: {
                    ...ad_request.ad,
                    app_ad: generated_app_ad
                },
            }
            return app_ad_response
    }
}

export const CreateAdAction = (ad_request: AdRequest) => async (dispatch: Dispatch<AdDispatchTypes>) => {

    dispatch(AdCreateRequestAction())

    try {

        const response = await generate_ad(ad_request)

        if (response!!.status === 200) {
            dispatch(AdCreateSuccessAction(response!!))
        } else {
            dispatch(AdCreateErrorAction(response!!))
        }
    } catch (error) {
        console.log(`error: ${error}`)
    }
}
