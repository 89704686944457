import {PageType} from "../Data/Page/Actions";
import {IoChatbubble, IoGrid, IoWallet} from "react-icons/io5";
import {AiFillNotification} from "react-icons/ai";

export const PAGE = {
    AD_PAGE: "AdPage",
    REPORT_PAGE: "ReportPage",
    WALLET_PAGE: "WalletPage",
    SUPPORT_PAGE: "SupportPage",
}

export const pages: PageType[] = [
    {
        id: PAGE.AD_PAGE,
        name: "إنشاء إعلان",
        icon: AiFillNotification,
    },
    {
        id: PAGE.REPORT_PAGE,
        name: "تقرير الإعلان",
        icon: IoGrid,
    },
    {
        id: PAGE.WALLET_PAGE,
        name: "المحفظة",
        icon: IoWallet,
    },
    {
        id: PAGE.SUPPORT_PAGE,
        name: "الدعم الفني",
        icon: IoChatbubble,
    },
]

export const getPage = (id: string) => {
    const index = pages.findIndex(page => page.id === id)
    return pages[index]
}
