import React from 'react';
import * as Chakra from "@chakra-ui/react";
import {theme} from "../../../Utils/Theme";
import {useDispatch, useSelector} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {RootStore} from "../../../Data/Store";
import {AnyAction} from "redux";
import {creators} from "../../../Utils/Creator";
import {CreatorType, SelectCreatorAction} from "../../../Data/Creator/Actions";
import {AdCreator} from "./AdCreator/AdCreator";

export const AdPage = () => {

    const creatorState = useSelector((state: RootStore) => state.creatorReducer)

    return (
        <>
            {creatorState.creator !== null ? <AdCreator/> : <AdSelector/>}
        </>
    );
}

const AdSelector = () => {

    const dispatch = useDispatch<ThunkDispatch<RootStore, any, AnyAction>>()

    const onSelectCreator = (creator: CreatorType) => {
        dispatch(SelectCreatorAction(creator))
    }

    return (
        <Chakra.Flex flexDir={"column"} gap={"5"}>

            <Chakra.Text
                textAlign={"center"}
                fontSize={"20px"}
                fontWeight={"bold"}
            >{"اختار إعلانك"}</Chakra.Text>

            <Chakra.SimpleGrid columns={[2, null, 2]} spacing='10px' w={"300px"}>
                {creators.map(creator => (
                    <Chakra.Flex
                        key={creator.id}
                        flexDir={"column"}
                        textAlign={"center"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={"2"}
                        bgColor={theme.light_green}
                        borderColor={theme.black}
                        borderWidth={"1px"}
                        borderRadius={"10px"}
                        p={"10px"}
                        cursor={"pointer"}
                        onClick={() => onSelectCreator(creator)}
                    >
                        <Chakra.Icon as={creator.icon} w={"30px"} h={"30px"} color={theme.black}/>
                        <Chakra.Text>{creator.name}</Chakra.Text>
                    </Chakra.Flex>
                ))}
            </Chakra.SimpleGrid>

        </Chakra.Flex>
    )
}