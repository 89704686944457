import {APP_SELECT, AppDispatchTypes} from "./Actions";
import {APP} from "../../Utils/App";

interface AppState {
    app: string,
}

const defaultState: AppState = {
    app: APP.HOME,
}

export const AppReducer = (state: AppState = defaultState, action: AppDispatchTypes): AppState => {
    switch (action.type) {
        case APP_SELECT:
            return {
                ...state,
                app: action.app
            }
        default:
            return state
    }
}
