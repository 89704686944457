import {Dispatch} from "redux";
import {
    InvitationCheckErrorAction,
    InvitationCheckRequestAction,
    InvitationCheckSuccessAction,
    InvitationDispatchTypes,
    InvitationResponse,
    InvitationRequest,
} from "./Actions";
import {PAGE} from "../../Utils/Page";

export const CheckInvitationAction = (invitation_request: InvitationRequest) => async (dispatch: Dispatch<InvitationDispatchTypes>) => {

    dispatch(InvitationCheckRequestAction())

    try {
        let response: InvitationResponse
        if (invitation_request.invitation.code === "1997") {
            response = {
                status: 200,
                message: "تم الدخول بنجاح",
                invitation: {
                    first_name: "",
                    code: invitation_request.invitation.code,
                    access: true,
                },
            }
        }
        else {
            response = {
                status: 400,
                message: "غير مسموح بالدخول!",
                invitation: {
                    first_name: "",
                    code: invitation_request.invitation.code,
                    access: false,
                },
            }
        }

        if (response.status === 200) {
            localStorage.setItem("first_name", `${response.invitation.first_name}`)
            localStorage.setItem("access", `${response.invitation.access}`)
            localStorage.setItem("page", PAGE.AD_PAGE)
            dispatch(InvitationCheckSuccessAction(response))
        } else {
            dispatch(InvitationCheckErrorAction(response))
        }
    } catch (error) {
        console.log(`error: ${error}`)
    }
}

